@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  ActionOverlayPopover
/* ---------------------------------------------------------------------- */

.action-overlay-popover {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
}

.action-overlay-popover-ellipsis {
  color: var(--color-icon-default);
  line-height: 0;
  padding: $space-small;

  svg {
    height: $space;
    width: $space;
  }

  &:hover {
    color: var(--color-text-link);
  }

  &.is-active {
    color: var(--color-text-link);
  }

  &.is-disabled {
    color: var(--color-text-faint);
    cursor: not-allowed;
  }
}
