@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Tab
/* ---------------------------------------------------------------------- */

.tab {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tab-processing {
  text-align: center;

  span {
    color: var(--color-text-link);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.tab-created-date-time {
  display: flex;
  margin-top: $space * 2;
}
