@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Progress Bar
/* ---------------------------------------------------------------------- */

.progress-bar {
  appearance: none;
  background: var(--color-surface-light);
  border: 0;
  border-radius: $border-radius;
  display: block;
  height: 6px;
  overflow: hidden;
  width: 100%;

  &::-webkit-progress-bar {
    background: var(--color-surface-light);
  }

  &::-webkit-progress-value {
    background: var(--color-text-subdued);
  }

  // has to be [class selector][:: element selector] to work
  &.is-complete::-webkit-progress-value {
    background: var(--color-icon-pressed);
  }
}

// split styles for Mozilla to prevent issues with Chrome
.progress-bar {
  &::-moz-progress-bar {
    background: var(--color-text-subdued);
  }

  &.is-complete::-moz-progress-bar {
    background: var(--color-icon-pressed);
  }
}

.progress-bar-label {
  @extend %label;
}
