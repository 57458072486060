@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Products
/* ---------------------------------------------------------------------- */

.products-searchbar {
  margin-bottom: $space-large;

  > div > div,
  input {
    width: 100%;
  }
}

.products-search-results {
  display: flex;
  justify-content: space-between;
  min-height: $space * 30;
}

.products-loader {
  width: 500px;
}

.products-empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $space;
}

.products-list {
  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  > li + li {
    margin-top: $space-small;
  }
}
