@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Questionnaire List
/* ---------------------------------------------------------------------- */

.questionnaire-list-add-question-button {
  padding: 0 math.div($space, 2);

  button {
    color: var(--color-icon-default);
    cursor: pointer;
    display: block;

    &:hover:not(:disabled) {
      color: var(--color-text-brand);
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  svg {
    height: $space-large;
    width: $space-large;
  }
}

.questionnaire-list-container {
  align-items: center;
  display: flex;
  flex-flow: column;
  margin-top: 2 * $space;
}
