@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Button
/* ---------------------------------------------------------------------- */

%button {
  border: 1px solid transparent;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  font-family: $font-family-mark-web-pro;
  font-size: 14px;
  height: $button-height;
  justify-content: center;
  line-height: inherit;
  padding: $space-small $space;
  text-wrap: nowrap;

  &:active,
  &:hover {
    transition: all $transition-duration;
  }

  &:focus-visible {
    @extend %outline-offset;
  }

  &:disabled {
    color: var(--color-text-faint);
    cursor: not-allowed;
  }

  & + & {
    margin-left: $space-small;
  }

  &.has-text {
    > svg {
      margin: auto $space-small auto 0;
    }
  }
}

.button-primary {
  @extend %button;
  background-color: var(--color-button-primary-default);
  color: #fff;

  &:hover {
    background-color: var(--color-button-primary-hover);
  }
  &:active {
    background-color: var(--color-button-primary-pressed);
  }
  &:disabled {
    background-color: var(--color-button-primary-disabled);
  }
}

.button-secondary {
  @extend %button;
  background-color: var(--color-button-secondary-default);
  border-color: var(--color-border-subdued);
  color: var(--color-text-subdued);

  &:hover {
    background-color: var(--color-button-secondary-hover);
    border-color: var(--color-border-subdued);
  }
  &:active {
    background-color: var(--color-button-secondary-pressed);
    border-color: var(--color-border-default);
  }
  &:disabled {
    background-color: var(--color-button-secondary-disabled);
    border-color: var(--color-border-subdued);
  }
}

.button-tertiary {
  @extend %button;
  background-color: var(--color-button-tertiary-default);
  color: #fff;

  &:hover {
    background-color: var(--color-button-tertiary-hover);
  }
  &:active {
    background-color: var(--color-button-tertiary-pressed);
  }
  &:disabled {
    background-color: var(--color-button-tertiary-disabled);
  }
}

.button-error {
  @extend %button;
  background-color: var(--color-button-error-default);
  color: #fff;

  &:hover {
    background-color: var(--color-button-error-hover);
  }
  &:active {
    background-color: var(--color-button-error-pressed);
  }
  &:disabled {
    background-color: var(--color-button-error-disabled);
  }
}
