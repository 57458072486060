@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Screen Reader Only
/* ---------------------------------------------------------------------- */

.screen-reader-only:not(:active):not(:focus) {
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -8px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
