@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Search
/* ---------------------------------------------------------------------- */

.search {
  display: inline-block;
  position: relative;
}

/* ---------------------------------------------------------------------- */
/* Searchbar
/* ---------------------------------------------------------------------- */

@keyframes spin {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.searchbar {
  position: relative;

  > div > input {
    padding-left: $space-large * 2;
  }

  > svg {
    color: var(--color-text-faint);
    min-width: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: $space;
  }
}

.searchbar-search-icon {
  left: $space;
}

.searchbar-loading-icon {
  animation: spin 1s linear infinite;
  left: $space;
}

.searchbar-close-icon {
  cursor: pointer;
  right: $space;

  &:hover path {
    color: var(--color-text-default);
  }
}

/* ---------------------------------------------------------------------- */
/* Search Results
/* ---------------------------------------------------------------------- */

.search-results {
  background-color: var(--color-surface-default);
  border-radius: $border-radius;
  box-shadow: $elevation-300;
  min-width: 100%;
  position: absolute;
  top: calc(100% + #{$space-small / 2});
  z-index: 100;

  &.is-scrollable {
    max-height: 500px;
    overflow-y: auto;
  }

  > li > a,
  > li > div {
    align-items: center;
    color: var(--color-text-default);
    cursor: pointer;
    display: flex;
    padding: $space;

    > svg {
      height: $space-large;
      margin-right: $space;
      width: $space-large;
    }
  }

  > li > a:focus,
  > li > a:hover,
  > li > div:focus,
  > li > div:hover,
  > li.is-active > a,
  > li.is-active > div {
    background-color: var(--color-surface-light);
  }
}
