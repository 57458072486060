@use 'sass:math';
@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Checkbox
/* ---------------------------------------------------------------------- */

.checkbox {
  min-width: 0; // required to prevent overflowing the sidebar when wrapped in a <div>

  > input {
    height: 0;
    margin: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 0;
  }

  > label {
    align-items: center;
    cursor: pointer;
  }

  .checkbox-help-text {
    color: var(--color-text-faint);
    flex-shrink: 0;
    white-space: nowrap;
  }

  .checkbox-icon {
    color: var(--color-icon-default);
    line-height: 0;

    svg {
      background-color: var(--color-surface-default);
      border-radius: $border-radius;
      height: $space;
      width: $space;
    }
  }

  .checkbox-label-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .checkbox-text {
    display: flex;
    gap: math.div($space-small, 2);
    min-width: 0; // required for .checkbox-label-text to properly truncate
  }

  &.is-active .checkbox-icon {
    color: var(--color-text-link);
  }

  &.is-disabled {
    opacity: 0.5;

    > label {
      cursor: not-allowed;

      &:hover .checkbox-label-text {
        text-decoration: initial;
      }
    }
  }

  > input:focus-visible + label .checkbox-label-text,
  > label:hover .checkbox-label-text {
    text-decoration: underline;
  }
}
