@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Builder
/* ---------------------------------------------------------------------- */

.builder-results {
  display: flex;
  justify-content: space-between;
  min-height: $space * 30;

  > div:first-child {
    flex-grow: 1;
  }
}

.builder-actions {
  display: flex;
  justify-content: flex-end;
  float: right;
}

.builder-actions-toolbar {
  bottom: $space-large;
  position: fixed;
}
