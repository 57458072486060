@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Maintenance View
/* ---------------------------------------------------------------------- */

.maintenance-view {
  align-items: center;
  bottom: 0;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;

  &:before {
    background: linear-gradient(to right, $color-green-100, $color-mint-100);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 60%;
    z-index: -3;
  }

  &:after {
    background: linear-gradient(to bottom, #fff, transparent);
    content: '';
    height: 350px;
    left: 0;
    position: absolute;
    right: 0;
    top: 60%;
    z-index: -2;
  }

  > h2 {
    color: var(--color-neutral-700);
    font-weight: 700;
    text-transform: uppercase;
  }
}

.maintenance-view-logo {
  color: $color-sapphire-500; // TODO: use a var()
  margin-bottom: $space-large;

  svg {
    height: 60px;
    width: auto;
  }
}

:global(.dark) {
  .maintenance-view {
    &:before {
      background: linear-gradient(to right, $color-neutral-900, $color-sapphire-500);
    }
    &:after {
      background: linear-gradient(to bottom, $color-sapphire-900, transparent);
    }
  }

  .maintenance-view-logo {
    color: #fff;
  }
}
