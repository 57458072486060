@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Pinned Answers
/* ---------------------------------------------------------------------- */

.pinned-answers {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $space-small;

  > li {
    align-items: center;
    display: flex;
    margin-bottom: math.div($space, 2);
    margin-right: $space;
  }
}
.pinned-answers-value {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.pinned-answers-pin {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: math.div($space-small, 5) !important;

  > span > svg {
    color: var(--color-text-subdued);
    width: 14px !important;
  }

  &.is-active {
    > span > svg {
      color: #fff;
    }
  }
}
