@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Loader View
/* ---------------------------------------------------------------------- */

.loader-view {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: $space-large * 5 auto;
  max-width: math.div($max-width, 2);
  text-align: center;

  > h2 {
    margin-bottom: $space-large;
  }

  > p {
    margin-top: $space-large;
  }
}

.loader-view-progress-bar {
  margin: auto;
  width: math.div($min-width, 5);
  > div {
    background: linear-gradient(
      to right,
      $color-green-100 0%,
      $color-mint-100 50%,
      $color-sapphire-100 100%
    );
  }
}

.loader-view-children {
  margin-top: $space * 2;
}
