@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Binary Data Bar
/* ---------------------------------------------------------------------- */

.binary-data-bar {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: flex-end;
  margin-top: $space-small;

  > div:first-child {
    flex: 0.25 0 $space + $space-small;
    display: flex;
    flex-direction: row;
    max-height: $space + $space-small;
    > div {
      background-color: var(--color-surface-light);
      border-radius: $border-radius;
    }
    > div:first-child {
      background-color: var(--color-icon-info);
    }
  }

  > :nth-child(2n) {
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    justify-content: space-between;
    padding-top: $space-small;
  }
}
