@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Attribute Options Results
/* ---------------------------------------------------------------------- */

.attribute-options-results {
  display: flex;
  margin-top: $space-small;
}

.attribute-options-results-left-column {
  flex: 0 0 150px;
  padding: $space-small 0;
  padding-right: $space-small;
  text-align: right;
}

.attribute-options-results-right-column {
  border-left: 2px solid var(--color-border-subdued);
  flex: 1 1 auto;
  padding: $space-small 0;
  padding-left: $space-small;
}
