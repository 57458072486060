@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Notification Dropdown
* ---------------------------------------------------------------------- */

$height: 300px;

.notification-dropdown {
  display: flex;
  flex-direction: column;
  width: 450px;
  max-width: 450px !important;

  > ul {
    height: $height;
    overflow-y: auto;
  }

  > ul > li {
    align-items: center;
    border-top: 1px solid var(--color-border-subdued);
    padding: $space-small;

    p {
      display: inline;
    }
  }

  > ul > li:first-of-type {
    border-top: 0;
  }

  > ul > li + li {
    margin-top: 0;
  }
}

.notification-dropdown-datetime {
  color: var(--color-text-faint);
}

.notification-dropdown-loading {
  height: $height;
}

.notification-dropdown-empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: $height;
  justify-content: center;
}

.notification-dropdown-footer {
  align-items: center;
  color: var(--color-text-faint);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
