/* ---------------------------------------------------------------------- */
/* Themes: Dark
/* ---------------------------------------------------------------------- */

.dark {
  // Borders
  --color-border-active: #{$color-neutral-100};
  --color-border-default: #{$color-neutral-700};
  --color-border-focus: #{$color-neutral-300};
  --color-border-subdued: #{$color-neutral-900}; // relies on --color-surface-default being sapphire-900

  // Buttons
  --color-button-error-default: #{$color-red-100};
  --color-button-error-disabled: #{$color-red-900};
  --color-button-error-hover: #{$color-red-300};
  --color-button-error-pressed: #{$color-red-500};

  --color-button-primary-default: #{$color-sapphire-100};
  --color-button-primary-disabled: #{$color-neutral-700};
  --color-button-primary-hover: #{$color-sapphire-300};
  --color-button-primary-pressed: #{$color-sapphire-500};

  --color-button-secondary-default: #{$color-sapphire-900};
  --color-button-secondary-disabled: #{$color-sapphire-900};
  --color-button-secondary-hover: #{$color-neutral-900};
  --color-button-secondary-pressed: #{$color-neutral-700};

  --color-button-tertiary-default: #{$color-neutral-700};
  --color-button-tertiary-disabled: #{$color-neutral-900};
  --color-button-tertiary-hover: #{$color-sapphire-500};
  --color-button-tertiary-pressed: #{$color-sapphire-300};

  // Elevation
  --color-elevation-rgb: 255, 255, 255;

  // Icons
  --color-icon-default: #{$color-neutral-300};
  --color-icon-hover: #{$color-sapphire-100};
  --color-icon-pressed: #{$color-sapphire-100};

  --color-icon-danger: #{$color-red-100};
  --color-icon-feedback: #{$color-blue-400};
  --color-icon-info: #{$color-blue-200};
  --color-icon-success: #{$color-green-100};
  --color-icon-warning: #{$color-yellow-100};

  // Modals
  --color-modal-backdrop: #000;
  --opacity-modal-backdrop: 0.35;

  // Surfaces
  --color-surface-blue: #{$color-blue-300};
  --color-surface-default: #{$color-sapphire-900};
  --color-surface-light: #{$color-neutral-900};
  --color-surface-medium: #{$color-neutral-700};
  --color-surface-inverse: #{$color-neutral-100};

  // Text
  --color-text-default: #{$color-neutral-50};
  --color-text-faint: #{$color-neutral-300};
  --color-text-subdued: #{$color-neutral-100};

  --color-text-brand: #{$color-sapphire-100};
  --color-text-error: #{$color-red-100};
  --color-text-inverse: #{$color-neutral-900};
  --color-text-link: #{$color-sapphire-100};
}
