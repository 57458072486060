@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Inputs
/* ---------------------------------------------------------------------- */

.inputs {
  flex: 0 0 100%;

  > ul {
    display: flex;
    margin-left: $space-small * 2.5;
    margin-top: math.div($space-small, 2);

    input {
      min-width: 0;
    }

    > li + li {
      margin-left: $space;
    }
  }
}
