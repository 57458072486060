@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Attribute Options
/* ---------------------------------------------------------------------- */

.attribute-options {
  h4 {
    margin: $space 0;
  }
}
