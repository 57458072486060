@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Loader Skeleton
/* ---------------------------------------------------------------------- */

.loader-skeleton {
  max-width: 100%;
  width: auto !important;
}
