@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Comment Input 
/* ---------------------------------------------------------------------- */

.comment-input {
  border: 1px dashed var(--color-border-default);
  border-radius: $border-radius;
  position: relative;

  > textarea {
    background-color: var(--color-surface-default);
    border: transparent;
    width: 100%;
  }
}

.comment-input-header {
  display: flex;
  margin-bottom: math.div($space-small, 2);
  padding: $space-small;

  > strong {
    line-height: 1;
    margin-right: math.div($space-small, 2);
  }
}

.comment-input-footer {
  align-items: flex-end;
  display: flex;
  padding: $space-small;

  > p {
    color: var(--color-text-subdued);
    flex-grow: 1;

    &.is-max-length {
      color: var(--color-text-error);
    }
  }
}

.comment-input:focus-within:before {
  bottom: -2px;
  border: 2px solid var(--color-border-focus);
  border-radius: $border-radius;
  content: '';
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
  z-index: -1;
}
