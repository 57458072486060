@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Groups
/* ---------------------------------------------------------------------- */

.groups {
  flex: 2;

  > ul {
    margin-top: $space-small;

    > li {
      align-items: center;
      background-color: var(--color-surface-light);
      border-radius: $border-radius;
      display: flex;
      line-height: 1;
      margin-bottom: $space-small;
      padding: $space-small;
      > svg {
        cursor: pointer;
        height: $space;
        margin-right: $space-small;
      }
    }
  }

  button {
    color: var(--color-text-default);
    cursor: pointer;
    margin-left: $space;
  }
}
