@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Navigation
/* ---------------------------------------------------------------------- */

.navigation {
  > li {
    align-items: center;
    border-left: 1px solid var(--color-border-subdued);
    display: flex;

    &:hover,
    &:focus {
      > a {
        color: var(--color-text-default);
        cursor: pointer;
      }
    }

    &.is-active {
      border-color: var(--color-border-active);

      > a {
        color: var(--color-text-default);
      }
    }

    &.is-disabled {
      a,
      svg {
        color: var(--color-text-faint);
        opacity: 0.75;
        pointer-events: none;
      }
    }
  }

  > li > a {
    color: var(--color-text-subdued);
    padding: math.div($space, 2) $space * 2;
    text-decoration: none;

    :global(.docs-story) & {
      pointer-events: none;
    }
  }

  > li > svg {
    color: var(--color-text-faint);
    margin-left: $space-small;
    min-width: 0;
  }
}
