@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Advanced Prompts
/* ---------------------------------------------------------------------- */

.advanced-prompts {
  border-bottom: 0 !important;
  flex: 1;
}

.advanced-prompts-list {
  display: table;
  margin-top: $space;

  > li {
    display: table-row;
    padding: math.div($space-small, 4);

    > span {
      display: table-cell;
      vertical-align: middle;
    }

    > span:first-of-type {
      text-align: right;
    }

    button {
      margin: math.div($space-small, 4) 0 math.div($space-small, 4) $space-small;
    }
  }
}
