@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Questionnaire
/* ---------------------------------------------------------------------- */

.questionnaire {
  h1 {
    margin-bottom: 0;
  }
}

.questionnaire-count {
  margin-left: $space-small;
}

.questionnaire-pricing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: $space;
}

.questionnaire-results {
  display: flex;
  flex-direction: row;
  gap: $space;

  > div:first-child {
    flex: 1;
  }
}

.questionnaire-search {
  display: block;
  margin-bottom: 2 * $space;
  margin-top: $space-small;

  input {
    width: 100%;
  }
}
