@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Profile
/* ---------------------------------------------------------------------- */

.profile {
  > li + li {
    margin-top: $space-large;
  }
}

.profile-actions {
  display: flex;
  justify-content: flex-end;
}

.profile-actions-toolbar {
  bottom: $space-large;
  position: fixed;
}

.profile-select-label {
  @extend %label;
}

.profile-select-label-container {
  align-items: center;
  display: flex;

  span {
    margin-left: math.div($space-small, 2);
    margin-bottom: math.div($space-small, 2);

    svg {
      display: block;
      color: var(--color-text-subdued);
      height: $space;
    }
  }
}
