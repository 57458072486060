@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Custom Group Status
/* ---------------------------------------------------------------------- */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-group-status {
  align-items: center;
  color: var(--color-text-brand);
  display: flex;

  > svg {
    margin-right: $space-small;
  }
}

.custom-group-status-loading-icon {
  animation: spin 1s linear infinite;
  color: var(--color-text-brand);
}
