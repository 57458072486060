@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Skip Logic Row
/* ---------------------------------------------------------------------- */

.skip-logic-row {
  display: flex;
  flex-flow: column;
  gap: math.div($space-large, 2);

  & > p {
    display: flex;
    align-items: center;
  }

  &.is-one-line {
    flex-flow: row;
    gap: math.div($space-small, 3);
  }
}
