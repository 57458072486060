@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Smart People Group
/* ---------------------------------------------------------------------- */

.modal-smart-people-group-content {
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: $space;
  }

  input {
    width: 100%;
  }
}

.modal-smart-people-group-select {
  width: 100%;
}
