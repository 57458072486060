@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal AskWhy
/* ---------------------------------------------------------------------- */

.modal-ask-why-select {
  display: block;
  margin-bottom: $space * 2;
}

.modal-ask-why-questions {
  li:not(:last-child) {
    margin-bottom: $space-small;
  }
}
