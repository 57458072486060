@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Modal
/* ---------------------------------------------------------------------- */

.modal {
  --bs-modal-margin: #{$space * 2};

  :global(.modal-body) {
    padding: 0 ($space * 2);

    &:first-child {
      padding-top: $space * 2;
    }
    &:last-child {
      padding-bottom: $space * 2;
    }
  }

  :global(.modal-content) {
    background: var(--color-surface-default);
    border: 1px solid var(--color-border-subdued);
    border-radius: $border-radius;
    box-shadow: $box-shadow;
  }

  :global(.modal-dialog) {
    max-width: math.div($max-width, 2);
    min-height: 75vh;
  }

  &.is-large {
    :global(.modal-dialog) {
      max-width: $max-width * 0.75;
    }
  }
}

.modal-close {
  color: var(--color-text-default);
  cursor: pointer;
  display: block;
  height: 24px;
  width: 24px;
}

.modal-footer {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: $space * 2;
}

.modal-header {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: $space * 2;

  h2 {
    margin-bottom: 0;
  }
}
