@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Feedback Footer
/* ---------------------------------------------------------------------- */

.feedback-footer {
  align-items: center;
  display: flex;
  gap: $space-small;
  justify-content: flex-end;
  padding: $space;
}
.feedback-footer-text {
  align-items: center;
  display: flex;

  svg {
    margin-right: math.div($space-small, 2);
    height: 16px;
    width: 16px;
  }

  > sup {
    font-size: 0.5em;
    vertical-align: super;
  }
}

.feedback-footer-actions {
  display: flex;
  align-items: center;

  > button {
    border: 1px solid $color-sapphire-100;
    border-radius: 50%;
    color: var(--color-text-default);
    padding: $space-small;

    &:first-of-type {
      margin-inline-end: math.div($space-small, 2);
    }

    &:hover,
    &.active {
      background-color: var(--color-surface-light);
    }
  }
}
