@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Validation Errors
/* ---------------------------------------------------------------------- */

.modal-validation-errors-list {
  h4 {
    margin-bottom: 0;
  }

  li:not(:first-child) {
    margin-top: $space;
  }
}

.modal-validation-errors-list-field {
  &:not(:last-child) {
    margin-bottom: math.div($space-small, 2);
  }
}
