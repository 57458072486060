@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Media
/* ---------------------------------------------------------------------- */

.media-image {
  align-items: center;
  border: 1px solid var(--color-border-default);
  display: inline-flex;
  flex-shrink: 0;
  height: 50px;
  justify-content: center;
  margin-right: $space-small;
  width: 50px;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.media-description {
  float: right;

  label:hover & p {
    text-decoration: underline;
  }
  span {
    color: var(--color-text-faint);
  }
}
