@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Date Selectors
/* ---------------------------------------------------------------------- */

.date-selectors {
  border: solid 1px var(--color-border-subdued);
  border-spacing: $space-small 0;
  display: table;
  padding: $space-large $space-small;
  width: 100%;

  > li {
    display: table-row;

    > div {
      display: table-cell;
      vertical-align: bottom;

      &:first-child {
        max-width: 320px;
      }

      &:nth-child(2) > div {
        width: 100%;
      }
    }

    > div > input {
      width: 100%;
    }

    > span {
      display: table-cell;
      font-size: 12px;
      padding-bottom: $space-small;
      text-align: left;
      vertical-align: bottom;
    }
  }

  > li + li > div {
    padding-top: $space-small;
  }
}

.date-selectors-limit {
  margin-bottom: 0;
  margin-top: $space;
}
