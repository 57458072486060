@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* No Data Placeholder
/* ---------------------------------------------------------------------- */

.no-data-placeholder {
  align-items: center;
  color: var(--color-text-subdued);
  height: 400px;
  font-size: 16px;
  font-weight: $font-weight-bold;
  justify-content: center;
}
