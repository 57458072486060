@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Confirm AskWhy
/* ---------------------------------------------------------------------- */

.modal-confirm-ask-why {
  :global(.modal-body) > p {
    margin-bottom: $space;
  }
}

.modal-confirm-ask-why-image {
  margin-bottom: 2 * $space;
  text-align: center;
}
