@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Input Color
/* ---------------------------------------------------------------------- */

.input-color-wrapper {
  display: inline-block;

  :global(.sketch-picker) {
    background: var(--color-surface-default) !important;
    border: 1px solid var(--color-border-subdued);
    box-shadow: $box-shadow !important;
    display: inline-block;
    margin-top: $space-small;
    position: absolute;
    z-index: 2;

    input {
      font-family: $font-family-mark-web-pro;
    }

    label {
      color: var(--color-text-default) !important;
    }
  }
}

.input-color-input-wrapper {
  cursor: pointer;
  position: relative;
}

.input-color-swatch {
  background-color: var(--color-surface-default);
  bottom: 1px;
  border-bottom-left-radius: $border-radius;
  border-right: 1px solid var(--color-border-default);
  border-top-left-radius: $border-radius;
  left: 1px;
  padding: math.div($space-small, 2);
  position: absolute;
  width: $space * 3;
  z-index: 1;

  > div {
    height: 28px;
    width: 100%;
  }
}

.input-color-input {
  min-width: 0;
  padding-left: $space * 4;
  pointer-events: none;
}
