@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question Logic
/* ---------------------------------------------------------------------- */

.question-logic {
  background-color: var(--color-surface-medium);
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  margin: 0 $space * 3;
  min-height: $space-small * 3;
  padding: 4px $space-small;

  &.is-compact-view {
    font-size: 12px;
    height: 24px;
    width: fit-content;

    > svg {
      height: $space;
      width: $space;
    }
  }
}

.question-logic-line {
  background-color: var(--color-border-focus);
  height: $space-small;
  width: 1px;
  margin-left: $space-small * 7;
}

.question-logic-minus-plus-buttons {
  margin-top: math.div($space-small, 4);
  & > span {
    width: auto;
  }
}

.question-logic-text {
  align-items: center;
  display: flex;
  gap: $space-small;
}
