@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Create Breakout
/* ---------------------------------------------------------------------- */

.create-breakout-modal-prompt {
  svg {
    color: var(--color-text-error);
    margin-right: math.div($space-small, 4);
  }
}
