@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Control
/* ---------------------------------------------------------------------- */

.control {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $space;
}
