@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Legend
/* ---------------------------------------------------------------------- */

.legend {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: initial;
  margin: $space-large 0;

  :first-child {
    margin-left: 0px;
  }

  > li + li {
    margin-top: $space-small;
  }
}

.legend-item {
  align-items: center;
  display: flex;
  flex-direction: row;

  > div {
    border-radius: 50%;
    height: $space-small;
    margin-right: math.div($space-small, 2);
    max-width: $space-small;
    width: 100%;
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
