@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Node
/* ---------------------------------------------------------------------- */

.node {
  border: 1px solid var(--color-border-subdued);
  border-left: 5px solid var(--color-border-default);
  border-radius: $border-radius;
  box-shadow: $box-shadow-shallow;
  height: 100%;
  padding: $space;
  > p {
    color: var(--color-text-subdued);
  }
  &.has-connector {
    &:before {
      border-left: 1px dotted var(--color-border-default);
      content: '';
      height: 30px;
      left: 50%;
      position: absolute;
      top: -30px;
    }
  }
}

.node-title {
  align-items: center;
  display: flex;
  justify-content: space-between;

  svg {
    color: var(--color-icon-default);
  }

  svg:hover {
    color: var(--color-text-link);
    cursor: pointer;
  }
}

.node-title-actions {
  align-items: center;
  display: flex;
  gap: $space-small;
}

.node-tree {
  margin-top: $space-large;
  > li {
    display: flex;
    border-top: 1px dashed var(--color-border-default);
    padding: math.div($space, 2) 0;
  }
  > li > div {
    display: flex;
    flex: 1 0 0;
    justify-content: space-between;
  }
  > li > div:nth-child(2) {
    margin-left: $space * 2;

    &.is-inline {
      justify-content: flex-end;
    }

    &.is-vertical {
      flex-direction: column;
      margin-left: 0;
    }
  }

  > li > div > strong:nth-child(2) {
    margin-left: $space;
  }
}

.node-ask-why {
  svg {
    margin-top: -2px;
    width: 18px; // matches AskWhy icon with SmartPeopleGroup
  }
}

.node-card {
  margin-top: $space;
  > li + li {
    margin-top: $space;
  }
}

.node-list {
  display: flex;
  flex-direction: column;
}

.node-list-card {
  display: flex;
  justify-content: space-between;
  > span {
    flex: 1;
  }
  > strong {
    flex: 1;
    text-align: right;
  }
}

.node-list-card-rows {
  border-bottom: 1px dashed var(--color-border-default);
  padding-bottom: $space;
  margin-left: $space;
  > li {
    margin-top: math.div($space, 2);
  }
}

.node-card-rows {
  > li {
    padding: math.div($space, 2) 0;
  }
  > li + li {
    border-top: 1px dashed var(--color-border-default);
  }
}

.node-item {
  flex-direction: column;
}

.node-column {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
