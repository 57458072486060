@import '@utilities/_variables.scss';

$survey-gpt-1: rgba(0, 168, 184, 0.25);
$survey-gpt-2: rgba(10, 239, 184, 0.25);

/* ---------------------------------------------------------------------- */
/* Survey GPT
/* ---------------------------------------------------------------------- */

.survey-gpt {
  background: linear-gradient(227deg, $survey-gpt-1 0%, $survey-gpt-2 100%);
  color: var(--color-text-default);

  &.is-hidden {
    display: none;
  }

  sup {
    font-size: 0.5em;
    vertical-align: super;
  }
}

.survey-gpt-disabled-link {
  opacity: 0.5;
  pointer-events: none; // because this a Link element
}

.survey-gpt-feedback {
  justify-content: flex-end;
  margin-top: $space-small;

  button + button {
    margin-left: 0 !important; // override Button + Button
  }
}

.survey-gpt-footer {
  align-items: center;
  font-size: 12px;
  margin-top: $space-small;

  a {
    text-wrap: nowrap;
  }
  > div:nth-child(2) {
    flex: 1;
    line-height: 1;
  }
}

.survey-gpt-icon {
  height: $space-large;
  width: $space-large;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.survey-gpt-loader {
  animation: spin 1s linear infinite;
  color: var(--color-text-brand);
  height: $space-large;
  width: $space-large;
}
