@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Custom Date Groups
/* ---------------------------------------------------------------------- */

.custom-date-groups {
  > li {
    align-items: center;
    display: flex;
  }

  > li + li {
    margin-top: $space-small;
  }

  > li > span {
    line-height: 1;
    margin-left: math.div($space, 2);

    svg {
      color: var(--color-text-default);
      height: $space;
    }
  }
}
