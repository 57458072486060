@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Login
/* ---------------------------------------------------------------------- */

.login {
  align-items: center;
  background:
    url('assets/data-wave.svg') center center no-repeat,
    linear-gradient(to right, $color-green-100, $color-mint-100);
  background-size: cover;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;

  :global(.dark) & {
    background:
      url('assets/data-wave.svg') center center no-repeat,
      linear-gradient(to right, $color-neutral-900, $color-sapphire-500);
  }

  &.is-verified-voices {
    background-image: url('assets/verified-voices.svg');
    :global(.dark) & {
      background:
        url('assets/verified-voices.svg') center center no-repeat,
        linear-gradient(to right, $color-neutral-900, $color-sapphire-500);
      background-size: cover;
    }
  }
}

.login-container {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: $border-radius;
  display: flex;
  flex: 100%;
  flex-direction: column;
  justify-content: space-between;
  max-width: math.div($max-width, 2.7);
  min-height: $space-large * 20;
  padding: $space-small * 7 $space-small * 7 $space-small * 3 $space-small * 7;

  :global(.dark) & {
    background: rgba(0, 44, 49, 0.95); // $color-neutral-900 but keeps 0.95 opacity
  }
}

.login-wrapper {
  text-align: center;
  width: 100%;

  > svg {
    margin: 0 auto $space * 2 auto;
    width: 75%;
  }

  > div,
  > div > form {
    display: grid;
    gap: $space;
  }

  input {
    min-width: 0;
    width: 100%;
  }
}

.login-logo {
  padding-bottom: $space-large;
  width: 75%;
}

.login-footer {
  color: var(--color-text-faint);
  margin-top: $space-large;
}
