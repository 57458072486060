@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Connector
/* ---------------------------------------------------------------------- */

.connector-bottom-border {
  border-bottom: dotted 1px var(--color-border-default);
}

.connector-right-border {
  border-right: dotted 1px var(--color-border-default);
}

.connector-left-border {
  border-left: dotted 1px var(--color-border-default);
}
