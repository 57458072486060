@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* NuLoader
/* ---------------------------------------------------------------------- */

@keyframes topcircle {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes bottomcircle {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes diagonalbar {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes whole {
  49% {
    transform: rotate(0deg);
  }
  63% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@keyframes leftbar {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes rightbar {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.nuloader {
  align-items: center;
  display: flex;
  height: $space-large * 6;

  &.is-centered {
    justify-content: center;
  }

  .nu-animation {
    margin: auto;
    animation: whole;
    animation-duration: 5s;
    animation-direction: normal;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    width: 68px;
    height: 67px;
    fill: none;
    .top-circle {
      animation: topcircle;
      opacity: 0;
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 19.53px;
      height: 19.53px;
      fill: $color-green-100;
      stroke-width: 2.7px;
    }
    .bottom-circle {
      animation: bottomcircle;
      opacity: 0;
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 19.53px;
      height: 19.53px;
      fill: $color-green-100;
      stroke-width: 2.7px;
    }
    .diagonal-bar {
      animation: diagonalbar;
      opacity: 0;
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 19.53px;
      height: 68.9006px;
      fill: $color-green-100;
      stroke-width: 2.7px;
    }
    .left-bar {
      animation: leftbar;
      opacity: 0;
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 19.53px;
      height: 54.4489px;
      fill: $color-mint-100;
      stroke-width: 2.7px;
    }
    .right-bar {
      animation: rightbar;
      opacity: 0;
      animation-duration: 5s;
      animation-direction: normal;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
      width: 19.53px;
      height: 54.4489px;
      fill: $color-mint-100;
      stroke-width: 2.7px;
    }
  }
}
