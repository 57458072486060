@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Item Breakouts
/* ---------------------------------------------------------------------- */

.item-breakouts {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
}

.item-breakouts-add {
  p {
    color: var(--color-text-subdued);
    margin-bottom: $space-small;
  }
}

.item-breakouts-loader {
  width: 100%;
}

.item-breakout {
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow-shallow;
  padding: $space;

  & + & {
    margin-top: $space;
  }
}

.item-breakouts-search {
  display: flex;
  margin-bottom: $space-small;

  > div {
    flex: 1;
  }

  > div > div,
  input {
    width: 100%;
  }

  button {
    margin-left: $space-small;
  }
}

.item-breakouts-search-empty-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $space-large * 2;
}
