@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Mentions Input
/* ---------------------------------------------------------------------- */
.mentions-input-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: math.div($space-small, 2);

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}

.mentions-input-label {
  @extend %label;
}

// all .mentions__ styles are within the react-mentions component
.mentions__control {
  background: var(--color-surface-light);
  border-radius: $border-radius;
  color: var(--color-text-default);
  font-family: $font-family-mark-web-pro;
  font-size: inherit;
  height: $button-height;
  padding: $space-small;

  &:focus-within {
    background: var(--color-surface-default);
  }
}

.mentions__highlighter {
  line-height: initial;

  strong {
    background-color: var(--color-surface-light);
    border-radius: $border-radius;
  }
}

.mentions__input {
  @extend %input;
}

.mentions__suggestions {
  background-color: var(--color-surface-default) !important;
  border-radius: $border-radius;
  box-shadow: $elevation-300;
  transform: translateY($space-large);
}

.mentions__suggestions__item {
  color: var(--color-text-default);
  padding: math.div($space-small, 2) $space-small;
}

.mentions__suggestions__item--focused {
  background-color: var(--color-surface-medium);
}

.mentions--multiLine {
  .mentions__control {
    min-height: $space * 3;

    & ~ div {
      background: none !important;
      margin-top: 0 !important;
    }
  }

  .mentions__highlighter {
    min-height: $space-large * 3;
  }
}
