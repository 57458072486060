@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Checkout
/* ---------------------------------------------------------------------- */

.checkout {
  display: flex;
  flex-direction: row;
  gap: 2 * $space;
}

.checkout-actions {
  margin-top: $space-small;
  text-align: right;
}

.checkout-column {
  flex: 1;
}

.checkout-payment-options {
  li + li {
    margin-top: $space;
  }
}
