@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Attributes
/* ---------------------------------------------------------------------- */

.attributes {
  h4 {
    margin-bottom: $space;
  }

  ul {
    margin-bottom: $space;
  }
}
