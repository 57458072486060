@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Overlay Popover
/* ---------------------------------------------------------------------- */

:global(.popover).overlay-popover {
  --bs-popover-bg: var(--color-surface-default);
  --bs-popover-border-radius: #{$border-radius};
  --bs-popover-border-width: 0px;
}

.overlay-popover {
  box-shadow: $elevation-300;
  font-family: $font-family-mark-web-pro !important;
  min-width: 225px;

  > div:first-of-type {
    display: none;
  }

  &.has-arrow {
    div:first-of-type {
      display: revert;
    }
  }

  > div {
    padding: 0;
  }

  h4 {
    margin: $space-small;
  }

  > ul > li > a,
  > ul > li > button {
    align-items: center;
    color: var(--color-text-default);
    cursor: pointer;
    display: flex;
    line-height: 1;
    padding: $space-small;
    width: 100%;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:hover {
      background-color: var(--color-surface-light);
    }

    &[aria-selected='true'] {
      background-color: var(--color-button-primary-default);
      color: #fff;
      cursor: default;
    }

    > span:hover {
      text-decoration: none;
    }

    > svg {
      margin-right: $space-small;
      max-height: $space;
    }
  }

  hr {
    background-color: var(--color-border-subdued);
    margin: 0;
  }

  svg {
    width: $space;
  }
}
