@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Madlibs
/* ---------------------------------------------------------------------- */

.madlibs {
  margin: math.div($space-small, 2) 0 $space 0;

  &.is-completed {
    margin-bottom: -$space-small;
  }
}

.madlib-wrapper {
  > button {
    margin-bottom: math.div($space, 2);
  }

  > span + button {
    margin-left: math.div($space-small, 2);
  }

  > button + span {
    margin-left: math.div($space-small, 4);
  }
}

.madlib-buttons {
  span {
    display: inline-flex;
    margin-left: math.div($space-small, 2);
    transform: translateY(3px);
  }
}
