@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Status Bar
/* ---------------------------------------------------------------------- */

.status-bar {
  column-gap: 2px;
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: $space * 2 0 math.div($space-small, 2);
  > div {
    flex: auto;
  }
}

.status-bar-shift {
  align-items: center;
  background-color: var(--color-surface-medium);
  display: flex;
  height: $space-small;
  justify-content: center;
  > div {
    align-items: center;
    border-radius: $border-radius * 5;
    box-shadow: 0 0 0 1px var(--color-border-subdued);
    display: flex;
    height: $space * 2;
    justify-content: center;
    width: $space * 2;

    svg {
      color: #fff;
      height: 13px;
      width: 13px;
    }

    &.rotate-180 {
      svg {
        transform: rotate(180deg);
      }
    }

    &.rotate-90 {
      svg {
        transform: rotate(90deg);
      }
    }
    &.rotate-270 {
      svg {
        transform: rotate(270deg);
      }
    }
  }
}

.status-bar-text {
  margin-top: $space;
  text-align: center;
}
