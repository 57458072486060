@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Add Question Dropdown
/* ---------------------------------------------------------------------- */

.add-question-dropdown {
  button {
    display: flex;
    justify-content: space-between;
    svg {
      margin-right: 0 !important;
    }
  }
  hr {
    display: none;
  }
}
