@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Panelists
/* ---------------------------------------------------------------------- */

.panelists-add-quota-group {
  text-align: center;

  button {
    color: var(--color-icon-default);
    cursor: pointer;

    &:hover:not(:disabled) {
      color: var(--color-text-brand);
    }
    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    svg {
      height: $space-large;
      width: $space-large;
    }
  }
}

.panelists-cards {
  display: flex;
  flex-direction: column;
  gap: $space * 2;
}

.panelists-pricing {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.panelists-pricing-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: -$space-small;

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}

.panelists-layout {
  display: flex;
  flex-direction: column;
  gap: $space;

  h1 {
    margin-bottom: 0;
  }
}
