@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Options
/* ---------------------------------------------------------------------- */

.options {
  display: flex;
  justify-content: space-between;
}

.options-select-all {
  margin-bottom: $space;
  > div > label {
    font-weight: $font-weight-bold;
  }
}

.options-list {
  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    > div > label {
      &:hover > span {
        color: var(--color-text-default);
      }

      > span {
        position: unset !important;
      }
    }

    .option-pin {
      color: var(--color-text-faint);
      cursor: pointer;
      display: none;
      height: 18px;
      margin-left: $space-small;

      &.is-pinned {
        display: inline-flex;
      }

      > button {
        > span > svg {
          color: var(--color-text-subdued);
          width: 14px !important;
        }

        > svg {
          color: var(--color-text-default);
        }
      }
    }
    &:hover .option-pin {
      display: inline-flex;
    }
  }

  > li + li {
    margin-top: $space-small;
  }
}
