@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Recommended Reports
/* ---------------------------------------------------------------------- */

.recommended-reports {
  > p {
    margin-bottom: $space;
  }
}

.recommended-reports-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  > li {
    display: flex;
    padding: $space-large 0;

    &:not(:first-child) {
      border-top: 1px solid var(--color-border-subdued);
    }
  }

  > li > svg {
    color: var(--color-text-default);
    height: 44px;
    margin-right: $space;
    max-width: 44px;
    width: 100%;
  }

  small {
    color: var(--color-text-error);
    display: inline-block;
    margin-top: math.div($space-small, 2);
    text-transform: uppercase;
  }
}
