@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Share
/* ---------------------------------------------------------------------- */

.modal-share {
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: $space;
  }
}

.modal-share-select {
  width: 100%;
}

.modal-share-textarea {
  max-width: 100%;
  min-width: 100%;
}
