/* ---------------------------------------------------------------------- */
/* Duplicate
/* ---------------------------------------------------------------------- */

.duplicate {
  &.is-disabled {
    pointer-events: none;
  }
}

.duplicate-modal {
  input {
    width: 100%;
  }
}
