@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Tabs
/* ---------------------------------------------------------------------- */

.tabs {
  align-items: flex-end;
  border-bottom: 1px solid var(--color-border-subdued);
  display: flex;
  margin-bottom: $space;
  margin-top: math.div(-$space, 2);

  > li {
    align-items: center;
    display: flex;
    flex: 0 1;
    white-space: nowrap;
  }

  > li + li {
    margin-left: $space * 2;
  }

  > li:first-child > a {
    margin-left: 0;
  }

  > li > a,
  > li > span {
    display: inline-block;
    color: var(--color-text-subdued);
    cursor: pointer;
    padding: math.div($space, 2) 0;
    position: relative;
    text-decoration: none;

    :global(.docs-story) & {
      pointer-events: none;
    }
  }

  > li > a > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-small / 2;
    > div {
      width: 20px;
      height: 20px;
      background-color: #f3f7f9;
      border-radius: 50%;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      align-items: center;
      display: flex;
      justify-content: center;

      > svg {
        color: var(--color-text-subdued);
        height: 16px;
        width: 16px;
      }
    }

    > span {
      text-decoration: none;
    }
  }

  > li:hover:not(.is-active):not(.is-locked) {
    > a,
    > span,
    > a > div {
      color: var(--color-text-default);
    }
  }

  > li.is-active {
    > a,
    > span,
    > a > div {
      color: var(--color-text-default);

      > div > div {
        background-color: var(--color-icon-feedback);
        > svg {
          color: var(--color-text-default);
        }
      }

      &:after {
        background-color: var(--color-text-link);
        content: '';
        height: 3px;
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
      }
    }
  }

  > li.is-locked {
    opacity: 0.5;
    pointer-events: none;

    > svg {
      color: var(--color-text-subdued);
      margin-left: $space-small;
      min-height: 0;
      min-width: 0;
    }
  }

  > li.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
