@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Survey Templates
/* ---------------------------------------------------------------------- */

.modal-survey-template-list {
  height: 316px;
  overflow: auto;

  > ul > li:not(:last-of-type) {
    margin-bottom: $space;
  }
}

.modal-survey-template-search {
  display: block;
  margin-bottom: $space;
  margin-top: $space-small;

  input {
    width: 100%;
  }
}
