@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Key Takeaways
/* ---------------------------------------------------------------------- */

.key-takeaways-footer {
  align-items: center;
  display: flex;
  float: right;
  gap: math.div($space-small, 2);
  justify-content: flex-end;
  padding-right: $space;

  > button {
    border: 1px solid $color-sapphire-100-transparent;
    border-radius: 50%;
    padding: $space-small;

    &:first-of-type {
      margin-inline-end: math.div($space-small, 2);
    }

    &:hover {
      background-color: $color-sapphire-100-transparent;
    }

    &.active {
      background: $gradient-sapphire-mint;
      border-color: $color-sapphire-100;
      color: var(--color-text-default);
    }
  }

  > svg {
    width: 18px;
  }

  sup {
    font-size: 0.5em;
    vertical-align: super;
  }
}
