@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Recommendation Footer
/* ---------------------------------------------------------------------- */

.recommendation-footer {
  align-items: center;
  display: flex;
  gap: $space * 2;
  justify-content: flex-end;
  padding: $space;

  > a {
    align-items: center;
    color: var(--color-text-default);
    display: flex;
    gap: 4px;
    position: relative;

    > svg {
      height: 16px;
      width: 16px;
    }

    &:hover {
      text-decoration: underline;
    }
  }
  > a:not(:last-child)::after {
    color: var(--color-text-default);
    content: '|';
    margin-left: $space;
    pointer-events: none;
    position: absolute;
    right: -18px;
  }
}
