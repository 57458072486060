@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Survey Toolbar
/* ---------------------------------------------------------------------- */

.survey-toolbar {
  padding-top: $space;
}

.survey-toolbar-input {
  background: transparent;
  border-style: dashed;
  font-size: 18px;
  width: 750px;

  &:focus,
  &:hover {
    border-color: var(--color-border-focus);
  }
}

.survey-toolbar-question-count {
  align-self: center;
}
