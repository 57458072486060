@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Logic Change Confirmation
/* ---------------------------------------------------------------------- */

.modal-logic-change-confirmation-body {
  display: flex;
  flex-flow: column;
  gap: $space;
  max-height: 70vh;
  overflow: auto;
}
