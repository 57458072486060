@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Share
/* ---------------------------------------------------------------------- */

.share {
  display: flex;
  flex-direction: column;
  margin: $space * 2 auto;
  max-width: $max-width;
  min-height: 100vh;
  padding: 0 $space;

  > img {
    margin-bottom: $space * 2;
    width: $space-large * 10;
  }
}

.share-body {
  flex-grow: 1;
}
