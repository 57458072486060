@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Consultant
/* ---------------------------------------------------------------------- */

.consultant-actions {
  display: flex;
  justify-content: center;

  button {
    color: var(--color-text-link);
    line-height: 1;
  }

  *:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
