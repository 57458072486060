@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question Placeholder
/* ---------------------------------------------------------------------- */

.question-placeholder-body {
  align-items: center;
  display: flex;
  gap: $space;
  justify-content: center;
}

.question-placeholder-container {
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow-shallow;
  cursor: pointer;
  gap: $space;
  margin-bottom: $space;
  padding: #{$space + $space-small} $space-small;
  width: $min-width * 0.75;
}

.question-placeholder-number {
  align-items: center;
  display: flex;
  font-weight: bold;
  justify-content: center;
  width: $space-large * 3;
}

.question-placeholder-text {
  align-items: center;
  color: var(--color-text-subdued);
  display: flex;
  flex: 1;
}
