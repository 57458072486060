@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Checkbox
/* ---------------------------------------------------------------------- */

.checkbox {
  align-items: center;
  display: flex;
  position: relative;

  input {
    display: none;
  }

  .checkbox-icon {
    color: var(--color-icon-default);
    line-height: 0;
    margin-right: $space-small;

    svg {
      background-color: var(--color-surface-default);
      border-radius: $border-radius;
      height: $space;
      width: $space;
    }
  }

  &.is-checked .checkbox-icon,
  &.is-partial .checkbox-icon {
    color: var(--color-text-link);
  }
}

.checkbox-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  margin-bottom: 0;

  &:hover {
    text-decoration: underline;
  }

  &.is-disabled {
    color: var(--color-text-faint);
    cursor: not-allowed;

    &:hover {
      text-decoration: initial;
    }

    .checkbox-icon {
      color: var(--color-border-subdued);
    }
  }

  > span:last-of-type {
    color: var(--color-text-faint);
    left: calc(100% + #{$space-small} / 2);
    position: absolute;
    white-space: nowrap;
  }
}
