@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Copy to Clipboard
/* ---------------------------------------------------------------------- */

.copy-to-clipboard {
  padding: $space-small;

  &:disabled {
    color: $color-mint-100;
    cursor: default;
  }
}
