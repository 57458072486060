@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Filter Group
/* ---------------------------------------------------------------------- */

.filter-group {
  > div:first-child {
    align-items: center;
    justify-content: space-between;
  }
}

.filter-group-button {
  align-items: center;
  color: var(--color-icon-default);
  cursor: pointer;
  display: inline-flex;
  height: $icon-size;

  svg {
    height: $icon-size;
    width: $icon-size;
  }

  &[aria-expanded='true'] {
    color: var(--color-icon-pressed);

    svg {
      transform: rotate(180deg);
    }
  }

  &:focus-visible {
    @extend %outline-offset;
  }
}

.filter-group-list {
  margin-top: 8px;
  padding-left: $space + $space-small; // checkbox width + checkbox margin-right
  position: relative;

  &[hidden] {
    display: none;
  }

  li:not(:last-child) {
    margin-bottom: $space-small;
  }
}
