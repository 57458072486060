@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Fieldset
/* ---------------------------------------------------------------------- */

.fieldset {
  min-width: 0;
}

.legend {
  @extend %label;
  border: 0; // override bootstrap <legend> styles
  width: 100%;
}
