@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Profile
/* ---------------------------------------------------------------------- */

.profile {
  li > * {
    width: 100%;
  }

  li + li {
    margin-top: $space-large;
  }

  ul {
    margin: $space-large 0;
    width: 100%;
  }
}

.profile-input {
  width: 100%;
}

.profile-select {
  width: 100%;
}

.profile-select-label {
  @extend %label;
  > svg {
    color: var(--color-text-error);
    margin-left: math.div($space-small, 2);
  }
}

.profile-select-label-container {
  align-items: center;
  display: flex;

  span {
    margin-left: math.div($space-small, 2);
    margin-bottom: math.div($space-small, 2);

    svg {
      display: block;
      color: var(--color-text-subdued);
      height: $space;
    }
  }
}
