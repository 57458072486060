@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* ModalDuplicateGroup
/* ---------------------------------------------------------------------- */

.modal-duplicate-group-p {
  margin-bottom: $space;
}

.modal-duplicate-group-input {
  display: block;
  width: 100%;
}
