@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Module
/* ---------------------------------------------------------------------- */

.module {
  > div {
    margin-bottom: $space * 2;
  }

  input {
    width: 100%;
  }

  > h1 {
    border-bottom: 1px solid var(--color-border-subdued);
  }
}

.module-search {
  width: 100%;
}
