@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Metrics
/* ---------------------------------------------------------------------- */

.metrics {
  align-items: center;
  display: flex;
  flex: 0 0 100%;
  gap: $space-small;
  margin-left: $space-small * 2.5;
  margin-top: math.div($space-small, 2);

  input {
    min-width: 0;
  }
}

.metrics-input-error {
  transform: translateY(23%);
}
