@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Step
/* ---------------------------------------------------------------------- */

.step {
  border-top: 2px solid transparent;
  color: var(--color-text-subdued);
  column-gap: $space;
  display: flex;
  flex-direction: row;
  margin-top: -1px;
  padding: $space-small $space;

  &:focus-visible {
    @extend %outline-offset;
  }

  &.is-complete {
    .step-icon {
      background: var(--color-border-default);
      color: var(--color-text-inverse);
    }
  }

  &.is-disabled {
    cursor: not-allowed;
  }

  &.is-active,
  &.is-past {
    border-top-color: var(--color-border-default);
  }

  &.is-complete.is-past {
    color: var(--color-text-default);

    .step-icon {
      background: var(--color-text-brand);
      border-color: var(--color-text-brand);
      color: #fff;
    }
  }

  &.is-active {
    .step-icon {
      background: transparent;
      border-color: var(--color-text-link);
      color: var(--color-text-link);
    }

    .step-label {
      color: var(--color-text-default);
      font-weight: bold;
    }
  }

  &:not(.is-active):not(.is-disabled):hover {
    cursor: pointer;

    .step-label {
      color: var(--color-text-link);
      text-decoration: underline;
    }
  }
}

.step-icon {
  align-items: center;
  background: transparent;
  border: 2px solid var(--color-border-default);
  border-radius: 50%;
  color: var(--color-text-faint);
  display: flex;
  flex-shrink: 0;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  justify-content: center;
  line-height: 0;
  text-decoration: none;
  width: 20px;

  > span {
    margin-top: -1px;
  }
}
