@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Loader
/* ---------------------------------------------------------------------- */

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(math.div(-$space-large, 3));
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY(math.div($space-large, 3));
  }
}

@keyframes circle-bounce {
  0%,
  100% {
    transform: translateY(-$space-small);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateY(0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translateY($space-large * 2 - math.div($space, 2));
  }
}

.loader {
  align-items: center;
  display: flex;
  height: $space-large * 6;

  &.is-centered {
    justify-content: center;
  }

  > div {
    animation: bounce 4s linear infinite;
    background-color: $color-mint-100;
    height: $space-large * 2;
    margin: 0 math.div($space, 6);
    position: relative;
    width: 12px;

    &:before {
      background-color: $color-mint-100;
      border-radius: 50%;
      content: '';
      height: $space;
      left: 0;
      position: absolute;
      top: math.div(-$space, 2);
      width: 12px;
    }

    &:after {
      background-color: $color-mint-100;
      border-radius: 50%;
      bottom: math.div(-$space, 2);
      content: '';
      height: $space;
      left: 0;
      position: absolute;
      width: 12px;
    }

    > div {
      animation: circle-bounce 8s linear infinite;
      border-radius: 50%;
      background-color: $color-green-100;
      height: $space;
      position: relative;
      width: 12px;
      z-index: 10;
    }
  }

  div:nth-child(1) {
    animation-delay: -0.5s;
  }

  div:nth-child(2) {
    animation-delay: -1s;
  }

  div:nth-child(3) {
    animation-delay: 0s;
  }

  div:nth-child(4) {
    animation-delay: 0.5s;
  }
}
