@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Results
/* ---------------------------------------------------------------------- */

.results-container {
  margin-top: 0;

  > li:not(:last-child) {
    margin-bottom: $space;
  }
}

.results-caption {
  color: var(--color-text-subdued);
  display: block;
  margin-left: $space + $space-small;
  margin-top: math.div($space, 2);

  &.is-disabled {
    color: var(--color-text-faint);
  }
}

.results-checkbox-wrapper {
  align-items: center;
  display: flex;
  gap: $space-small;
}

.results-edit {
  cursor: pointer;
  line-height: 0;

  svg {
    height: $space;
    width: $space;
  }
}

.results-folder {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 1.4;

  &:hover {
    text-decoration: underline;
  }

  svg {
    color: var(--color-icon-default);
    margin-right: $space-small;
    width: $space;
  }
}

.results-image {
  border: 1px solid var(--color-border-default);
  max-height: $space * 3;
}

.results-question {
  display: flex;
  justify-content: space-between;
}
