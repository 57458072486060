@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Select
/* ---------------------------------------------------------------------- */

.select {
  display: inline-block;
  min-width: 320px;

  :global(.select__control) {
    background-color: var(--color-surface-light);
    border: 1px solid var(--color-border-default);
    border-radius: $border-radius;
    min-height: $button-height;
    transition: none;

    &:hover {
      border-color: var(--color-border-default);
    }
  }

  :global(.select__control--is-focused) {
    background-color: var(--color-surface-default);
    border: 1px solid var(--color-border-focus);
    box-shadow: none;

    &:hover {
      border-color: var(--color-border-default);
    }

    :global(.select__placeholder) {
      color: var(--color-text-subdued);
    }
  }

  :global(.select__value-container) {
    color: var(--color-text-default);

    & ~ div,
    & > div {
      color: inherit;
      margin: 0;
      padding: 0;
    }
  }

  :global(.select__value-container--is-multi) {
    padding-bottom: 0 !important;
    padding-top: math.div($space-small, 2) !important;

    > :global(.select__placeholder) {
      padding-bottom: 5px !important;
    }
  }

  :global(.select__multi-value) {
    background-color: var(--color-surface-medium);
    margin: 0 $space-small math.div($space-small, 2) 0 !important;
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  :global(.select__multi-value__label) {
    color: var(--color-text-default);
    padding: math.div($space-small, 4) math.div($space-small, 2);

    > svg {
      color: var(--color-text-faint);
      margin-right: $space-small;
    }
  }

  :global(.select__placeholder) {
    color: var(--color-text-subdued);
    margin: 0;

    & ~ div {
      margin: 0;
      padding: 0;
    }
  }
  :global(.select__multi-value__remove) {
    &:hover {
      background-color: var(--color-surface-medium);
      color: var(--color-text-default);
      cursor: pointer;
    }
  }

  :global(.select__multi-value--is-disabled) {
    color: hsl(0, 0%, 60%);

    > div {
      color: hsl(0, 0%, 60%);
    }
  }

  :global(.select__indicator-separator) {
    display: none;
  }

  :global(.select__loading-indicator) ~ :global(.select__dropdown-indicator) {
    display: none;
  }

  :global(.select__dropdown-indicator) {
    width: 40px;
  }

  :global(.select__dropdown-indicator),
  :global(.select__clear-indicator) {
    color: var(--color-text-subdued);
    cursor: pointer;

    svg {
      min-height: 0;
      min-width: 0;
    }

    &:hover {
      color: var(--color-text-default);
    }
  }

  :global(.select__menu) {
    background-color: var(--color-surface-default);
    border-radius: $border-radius;
    box-shadow: $elevation-300;
    z-index: 40;
  }

  :global(.select__menu-list) {
    padding: 0;
  }

  :global(.select__option) {
    > svg {
      color: var(--color-text-faint);
      margin-right: $space-small;
    }
  }

  :global(.select__option:active) {
    background-color: var(--color-surface-light);
  }

  :global(.select__option:not(.select__option--is-selected)):hover {
    background: var(--color-surface-light);
  }

  :global(.select__option--is-focused) {
    background: var(--color-surface-light);
  }

  :global(.select__option--is-selected),
  :global(.select__option--is-selected:active) {
    background: var(--color-button-primary-default);
  }

  &.is-error {
    :global(.select__control) {
      border-color: var(--color-text-error);
    }
  }
}

.select-label {
  @extend %label;
  > svg {
    color: var(--color-text-error);
    margin-left: math.div($space-small, 2);
  }
}

.select-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: $space-small;

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    width: $space-small;
  }
}
