@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Table
/* ---------------------------------------------------------------------- */

.table {
  > div:first-of-type {
    display: flex;
    gap: $space;
    margin: $space-large 0;

    > div > div {
      min-width: 0;
    }
  }

  > h3 {
    color: var(--color-text-brand);
  }
}
