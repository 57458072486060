@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Index Bar
/* ---------------------------------------------------------------------- */

$divider: 2px;

.index-bar {
  background-color: var(--color-surface-light);
  border-radius: $border-radius;
  display: flex;
  height: $space;
  margin-left: $space-small;
  overflow: hidden;
  position: relative;
  width: 150px;

  &:before {
    background-color: var(--color-surface-medium);
    border-left: $divider solid var(--color-text-inverse);
    border-right: $divider solid var(--color-text-inverse);
    content: '';
    height: 100%;
    left: calc(50% - 3px);
    position: absolute;
    width: $divider;
  }

  > span {
    display: block;
  }

  > span:first-of-type {
    background-color: var(--color-surface-medium);
  }

  > span:nth-child(2) {
    background-color: $color-yellow-100;
    bottom: 0;
    position: absolute;
    right: calc(50% + 3px);
    top: 0;
  }

  > span:last-of-type {
    background-color: $color-green-100;
    bottom: 0;
    left: calc(50% + 3px);
    position: absolute;
    top: 0;
  }

  > svg {
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
