@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Announcement
/* ---------------------------------------------------------------------- */

%announcement {
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: $space-small;
  margin-bottom: $space;
  padding: $space;
  position: relative;

  > div:first-child {
    display: flex;
    gap: $space;
  }

  &.is-centered {
    text-align: center;
  }
}

.announcement-ai {
  @extend %announcement;
  background: $gradient-sapphire-mint;
  color: var(--color-text-default);
}

.announcement-close {
  line-height: 0;

  button {
    cursor: pointer;
  }
}

.announcement-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  pre {
    text-wrap: wrap;
  }
}

.announcement-error {
  @extend %announcement;
  background-color: var(--color-button-error-default);
  color: #fff;
}

.announcement-feedback {
  @extend %announcement;
  background-color: transparent;
  color: var(--color-text-default);
  border: 1px solid var(--color-border-subdued);
  padding: 14px;
}

.announcement-footer {
  border-top: 1px solid $color-sapphire-100-transparent;
  padding-top: $space-small;
}

.announcement-footer-feedback {
  border-top: 1px solid var(--color-border-subdued);
  padding-top: $space-small;
  margin-top: $space;
}

.announcement-footer-recommendation {
  border-top: 1px solid $color-blue-75;
  padding-top: $space-small;
  margin-top: $space;
}

.announcement-icon {
  line-height: 0;

  svg {
    height: 24px;
    width: 24px;
  }
}
.announcement-icon-feedback {
  background-color: var(--color-surface-light);
  border-radius: 50%;
  height: 100%;
  padding: $space-small;
}

.announcement-icon-recommendation {
  background-color: var(--color-icon-feedback);
  border-radius: 50%;
  height: 100%;
  padding: $space-small;
}

.announcement-info {
  @extend %announcement;
  background-color: var(--color-surface-light);
  color: var(--color-text-default);
}

.announcement-recommendation {
  @extend %announcement;
  background-color: var(--color-surface-blue);
  color: var(--color-text-default);
}

.announcement-success {
  @extend %announcement;
  background-color: $color-green-100;
  color: $color-neutral-900;
}

.announcement-warn {
  @extend %announcement;
  background-color: $color-yellow-100;
  color: $color-neutral-900;
}
