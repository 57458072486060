@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Breakout
/* ---------------------------------------------------------------------- */

.breakout-statement,
.breakout-statement-preview {
  display: inline-block;
  > div {
    display: inline-block;
  }
}

.breakout-more-statement {
  display: inline-block;
  margin-left: $space-small;
}

.modal-breakout-statements {
  h2 {
    text-transform: uppercase;
  }
}
