@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* List Page
/* ---------------------------------------------------------------------- */

.list-page-header {
  align-content: stretch;
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: $space;
}

.list-page-search {
  display: flex;
  justify-content: space-between;

  > div {
    flex: 1;
  }

  > div > div,
  input {
    width: 100%;
  }

  button {
    margin-left: $space;
  }
}

.list-page-filters {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: $space;

  > div {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > div > div {
      background: none;
      margin-right: $space;
      min-width: 0;
    }
  }
}
