@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Other Please Specify Table
/* ---------------------------------------------------------------------- */

.other-please-specify-table {
  @extend %table;

  .can-expand {
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      background: var(--color-surface-light);
    }
  }

  .is-sub-row {
    padding-left: $space-large;
  }
}

.other-please-specify-table-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: math.div($space-small, 2);
}
