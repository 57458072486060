@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Module List
/* ---------------------------------------------------------------------- */

.module-list-favorite {
  border-bottom: 1px solid var(--color-border-subdued);
  padding-bottom: $space * 2;
  > h4 {
    margin-top: $space * 2;
  }
}
