@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Flex
/* ---------------------------------------------------------------------- */

%flex {
  display: flex;
  gap: $space;

  &.is-small {
    gap: $space-small;
  }
  &.is-large {
    gap: $space-large;
  }
  &.is-x-large {
    gap: 2 * $space;
  }
}

.flex-column {
  @extend %flex;
  flex-direction: column;
}
.flex-column-reverse {
  @extend %flex;
  flex-direction: column-reverse;
}
.flex-row {
  @extend %flex;
  flex-direction: row;
}
.flex-row-reverse {
  @extend %flex;
  flex-direction: row-reverse;
}
