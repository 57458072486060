@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Feedback Popover
/* ---------------------------------------------------------------------- */

.feedback-popover {
  > button {
    margin: 0 $space-small $space-small $space-small;
    width: calc(100% - 2 * $space-small);
  }
}

.feedback-popover-list {
  margin: $space-small;
  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;

    > div {
      width: 100%;

      > input {
        margin-bottom: $space-small;
        min-width: 0;
        width: 100%;
      }
    }
  }

  > li + li {
    margin-top: $space-small;
  }
}
