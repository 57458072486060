@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Sidebar
/* ---------------------------------------------------------------------- */

.sidebar {
  align-self: stretch;
  flex-grow: 0;
  max-width: $sidebar-max-width;
  width: $sidebar-width;
}
