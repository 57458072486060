@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Table Data
/* ---------------------------------------------------------------------- */

.table-data {
  position: relative;

  & + & {
    margin-top: $space-large * 2;
  }
}

.table-data-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: math.div($space-small, 2);

  > h4 {
    margin-bottom: 0;
    margin-right: $space;
    text-transform: uppercase;
  }
}

.table-data-table {
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: math.div($space, 2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-surface-light);
    border-radius: 4px;
    transition: background-color 0.2s ease-in-out;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-surface-medium);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--color-surface-default);
  }
}

.table-data-thead {
  color: var(--color-text-subdued);
  text-transform: uppercase;
  user-select: none;
  z-index: 30 !important;
}

.table-data-tfoot {
  display: flex;
  position: relative !important;

  .table-data-tf {
    padding: math.div($space-small, 2) $space-small;
    position: relative;

    &:first-of-type {
      background-color: var(--color-surface-default);
      left: 0;
      position: sticky;
      z-index: 20;
    }

    &:first-of-type:not(:last-of-type) {
      border-right: 1px solid var(--color-border-subdued);
      color: var(--color-text-subdued);
      font-size: 12px;
      text-transform: uppercase;
    }

    &:nth-child(n + 2) {
      justify-content: flex-end;
      text-align: right;
    }
  }
}

.table-data-tr {
  display: inline-flex !important;
  min-width: 100% !important;
  &.is-loading {
    border-bottom: 1px solid var(--color-border-subdued);
    padding: math.div($space-small, 2) $space-small;
  }
}

.table-data-tr:not(.is-loading):hover .table-data-td:not(.is-selected):before {
  background-color: var(--color-surface-light);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.table-data-th,
.table-data-td {
  position: relative;

  &:first-of-type {
    left: 0;
    position: sticky;
    z-index: 20;
  }

  &:first-of-type:not(:last-of-type) {
    border-right: 1px solid var(--color-border-subdued);
  }

  &:nth-child(n + 2) {
    justify-content: flex-end;
    text-align: right;
  }

  > div {
    display: flex;
  }

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
  }
}

.table-data-th {
  background-color: var(--color-surface-default);
  border-bottom: 1px solid var(--color-border-default);
  display: flex;
  padding: $space-small;
  text-align: left;
  vertical-align: inherit;

  &.is-sortable:hover {
    color: var(--color-text-default);
  }

  p {
    font-size: 12px;
  }
}

.table-data-td {
  align-items: center;
  border-bottom: 1px solid var(--color-border-subdued);
  display: flex;
  padding: math.div($space-small, 2) $space-small;
  user-select: none;

  &:not(.is-loading):hover {
    background-color: var(--color-surface-medium) !important;
  }

  &:not(:first-of-type):not(.is-loading):hover:after {
    content: '';
    position: absolute;
    background-color: var(--color-surface-light);
    left: 0;
    top: -5000px;
    height: 10000px;
    width: 100%;
    z-index: -1;
  }

  &.is-active p {
    display: block;
    overflow-wrap: break-word;
    white-space: normal;
  }

  &.is-active:before {
    background-color: rgba($color-sapphire-100, 0.2) !important;
    content: '';
    bottom: 0;
    border: 2px solid var(--color-text-link);
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1 !important;
  }

  &.is-selected {
    background: var(--color-surface-light) !important;
  }

  &.is-header {
    background: var(--color-surface-light) !important;
  }

  &.is-negative {
    background: rgba($color-red-100, 0.7) !important;
  }

  &.is-soft-negative {
    background: rgba($color-red-100, 0.4) !important;
  }

  &.is-soft-positive {
    background: rgba($color-green-100, 0.4) !important;
  }

  &.is-positive {
    background: rgba($color-green-100, 0.75) !important;
  }

  &:first-of-type {
    background-color: var(--color-surface-default);
  }
}

.table-data-loader {
  &:before {
    background-color: var(--color-surface-default);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.9;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 30;
  }

  > div {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 35;
  }
}

.table-data-empty-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $space-large * 4;
}
