@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Comment
/* ---------------------------------------------------------------------- */

.comment {
  border-left: solid 3px var(--color-border-default);
  margin: $space-large 0;
  word-break: break-word;

  &.is-current-user-comment {
    border-color: var(--color-icon-info);
  }

  > div {
    margin-left: $space;
    margin-top: $space-small;
  }

  > div:first-of-type {
    > strong {
      color: var(--color-text-default);
    }
  }

  > div:last-of-type {
    > button {
      color: var(--color-text-link);
      cursor: pointer;
      padding-right: $space-small;

      &:hover,
      :focus {
        text-decoration: underline;
      }
    }
  }

  .comments-comment-text {
    display: flex;

    > div {
      background-color: var(--color-surface-light);
      border-radius: $border-radius;
      margin-right: math.div($space-small, 2);
      padding: 0 math.div($space-small, 2);

      &:not(:first-child) {
        margin-left: math.div($space-small, 2);
      }
    }
  }
}
