@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Open Ended Table
/* ---------------------------------------------------------------------- */

.open-ended-table {
  @extend %table;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.open-ended-table-actions {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: math.div($space-small, 2);
}
