@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Chart
/* ---------------------------------------------------------------------- */

.chart {
  position: relative;

  > div:first-of-type {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  :global(.highcharts-axis),
  :global(.highcharts-axis-labels),
  :global(.highcharts-legend-item),
  :global(.highcharts-no-data) {
    text {
      fill: var(--color-text-subdued) !important;
    }
  }
  :global(.highcharts-background) {
    fill: var(--color-surface-default);
  }
  :global(.highcharts-container) {
    svg,
    svg * {
      font-family: $font-family-mark-web-pro !important;
      font-size: 13px !important;
    }
  }
  :global(.highcharts-contextbutton) {
    path {
      stroke: var(--color-text-default);
      stroke-width: 2;
    }
    text {
      fill: var(--color-text-default) !important;
    }
  }
  :global(.highcharts-data-label) text {
    fill: var(--color-text-default) !important;
  }
  :global(.highcharts-text-outline) {
    stroke: var(--color-surface-default);
  }
}

:global(.dark) .chart :global(.highcharts-point) {
  stroke: var(--color-surface-default);
}

.chart-loader {
  &:before {
    background-color: var(--color-surface-default);
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0.9;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
  }

  > div {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
  }
}
