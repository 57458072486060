@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Add To Folder
/* ---------------------------------------------------------------------- */

.add-to-folder {
  ul {
    margin-top: $space-large;

    > li > div:nth-child(2) {
      margin-left: $space-small * 2.5;
      margin-top: math.div($space-small, 2);
    }

    > li > div {
      > div,
      > input {
        width: 100%;
      }
    }

    > li + li {
      margin-top: $space-small;
    }
  }
}
