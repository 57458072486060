@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- /
/* Item Answers
/* ---------------------------------------------------------------------- */

.item-answers {
  overflow: hidden;
  width: 100%;

  &.is-expandable {
    margin-bottom: $space-large * 2.5;

    &:before {
      background: linear-gradient(to top, var(--color-surface-default) 0%, transparent 75%);
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &.is-expanded:before {
      content: none;
    }
  }

  > div + div {
    margin-top: math.div($space-small, 2);
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    > li {
      margin-bottom: $space-small;
      margin-right: $space-small;
    }
  }
}

.item-answers-expanded {
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  margin-bottom: $space-small;
  position: absolute;
  right: 0;

  > button {
    z-index: 1;
  }
}
