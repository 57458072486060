@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* AreaOfInterest
/* ---------------------------------------------------------------------- */

.area-of-interest {
  padding-bottom: math.div($space-small, 2);
  > p {
    margin-bottom: $space-large;
  }
  > div {
    margin-bottom: $space * 3;
  }
}
