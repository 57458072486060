@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Product Search
/* ---------------------------------------------------------------------- */

.product-search {
  padding: $space-small !important;
}

.product-search-overlay-popover {
  min-width: 1000px;
}
