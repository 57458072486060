@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Textarea
/* ---------------------------------------------------------------------- */

.textarea {
  background-color: var(--color-surface-light);
  border: 1px solid var(--color-border-default);
  border-radius: $border-radius;
  color: var(--color-text-default);
  display: block;
  font-family: $font-family-mark-web-pro;
  min-height: 100px;
  min-width: 420px;
  padding: $space-small;

  &::placeholder {
    color: var(--color-text-subdued);
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:focus {
    background-color: var(--color-surface-default);
    border-color: var(--color-border-focus);
    outline: none;

    &::placeholder {
      color: var(--color-text-faint);
    }
  }

  &.is-error {
    border-color: var(--color-text-error);
  }
}

.textarea-label {
  @extend %label;
}

.textarea-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: $space-small;

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    width: $space-small;
  }
}
