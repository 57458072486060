@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Orders
/* ---------------------------------------------------------------------- */

.orders {
  margin-top: $space-small;

  > li {
    line-height: 1.1;
    margin-bottom: $space-small;
    padding: $space-small;
  }
}
