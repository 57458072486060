@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Button Text
/* ---------------------------------------------------------------------- */

.button-text {
  align-items: center;
  background: none;
  border: none;
  color: var(--color-button-primary-default);
  cursor: pointer;
  display: inline-flex;
  font-family: $font-family-mark-web-pro;
  line-height: 1;
  white-space: nowrap;

  &:focus,
  &:hover {
    color: var(--color-button-primary-hover);
  }

  &:active {
    color: var(--color-button-primary-pressed);
  }

  &[disabled] {
    color: var(--color-text-faint);
    cursor: not-allowed;
  }

  svg {
    height: $space;
    margin-right: $space-small;
    width: $space;
  }
}
