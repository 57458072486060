@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Get Tooltip Content
/* ---------------------------------------------------------------------- */

.truview-date-options-list {
  list-style-type: disc;
  padding-left: $space-large;
}
