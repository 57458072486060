@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Group Madlib
/* ---------------------------------------------------------------------- */

.group-madlib {
  margin: math.div($space-small, 2) 0 $space 0;

  &.is-completed {
    margin-bottom: -$space-small;
  }

  > button {
    margin-bottom: math.div($space, 2);
  }

  > span + button {
    margin-left: math.div($space-small, 2);
  }

  > button + span {
    margin-left: math.div($space-small, 4);
  }
}
