@import '../../../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Custom Export
/* ---------------------------------------------------------------------- */

.modal-custom-export-preview-wrapper {
  text-align: center;

  > h4 {
    margin-top: $space;
  }
}

.modal-custom-export-preview {
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  padding: $space;
  pointer-events: none;
}

.modal-custom-export-options {
  > li + li {
    margin-top: $space;
  }

  input {
    width: 100%;
  }
}
