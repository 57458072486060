@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Selections
/* ---------------------------------------------------------------------- */

.selections {
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  height: 100%;
  padding: $space $space math.div($space, 2) $space;
  width: math.div($max-width, 3);

  > h3 {
    color: var(--color-text-brand);
  }

  > ul {
    margin-top: $space-small;

    > li {
      margin-bottom: $space-small;
    }

    > li > div {
      align-items: center;
      background-color: var(--color-surface-light);
      border-radius: $border-radius;
      display: flex;
      justify-content: space-between;
      line-height: 1;
      padding: $space-small;

      > div {
        display: flex;

        > svg {
          height: $space;
          margin-right: $space-small;
        }
      }

      > svg {
        cursor: pointer;
      }
    }
  }

  button {
    cursor: pointer;
    margin-left: $space;
  }
}
