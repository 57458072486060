@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Prompt Errors
/* ---------------------------------------------------------------------- */

.modal-prompt-errors {
  li + li {
    margin-top: $space-small;
  }

  svg {
    color: var(--color-text-error);
    margin-right: math.div($space-small, 4);
  }
}
