/* ---------------------------------------------------------------------- */
/* Rename
/* ---------------------------------------------------------------------- */

.rename {
  &.is-disabled {
    pointer-events: none;
  }
}

.rename-modal {
  input {
    width: 100%;
  }
}
