@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Download
/* ---------------------------------------------------------------------- */
.download {
  &.is-disabled {
    pointer-events: none;
  }
}
