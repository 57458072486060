@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Results
/* ---------------------------------------------------------------------- */

.results-card-header {
  justify-content: space-between;
}

.results-count {
  flex-shrink: 0;
}
