@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Email   
/* ---------------------------------------------------------------------- */

.email {
  li + li {
    margin-top: $space-large;
  }
}

.email-actions {
  display: flex;
  justify-content: flex-end;
}

.email-actions-toolbar {
  bottom: $space-large;
  position: fixed;
}
