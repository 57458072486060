@use 'sass:math';
@import './_variables.scss';
@import './styles/themes/_index.scss';

/* ---------------------------------------------------------------------- */
/* Reset CSS
/* ---------------------------------------------------------------------- */

@import 'reset-css';

/* ---------------------------------------------------------------------- */
/* Bootstrap
/* ---------------------------------------------------------------------- */

$modal-backdrop-bg: var(--color-modal-backdrop);
$modal-backdrop-opacity: var(--opacity-modal-backdrop);
$progress-bar-bg: $color-mint-100;
$progress-height: math.div($space-small, 1.5);

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/progress';

/* ---------------------------------------------------------------------- */
/* Fonts
/* ---------------------------------------------------------------------- */

@font-face {
  font-family: 'FFMarkWebPro';
  src: url('./assets/fonts/3717A1_0_0.eot');
  src:
    url('./assets/fonts/3717A1_0_0.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/3717A1_0_0.woff2') format('woff2'),
    url('./assets/fonts/3717A1_0_0.woff') format('woff'),
    url('./assets/fonts/3717A1_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'FFMarkWebPro';
  font-weight: bold;
  src: url('./assets/fonts/3717A1_1_0.eot');
  src:
    url('./assets/fonts/3717A1_1_0.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/3717A1_1_0.woff2') format('woff2'),
    url('./assets/fonts/3717A1_1_0.woff') format('woff'),
    url('./assets/fonts/3717A1_1_0.ttf') format('truetype');
}

/* ---------------------------------------------------------------------- */
/* Global Styles
/* ---------------------------------------------------------------------- */

* {
  box-sizing: border-box;
}

body {
  background: var(--color-surface-default);
  color: var(--color-text-default);
  font-family: $font-family-mark-web-pro;
  font-size: 14px;
  line-height: 1.4;
  min-width: $min-width;
}

:focus,
:focus-visible {
  outline-color: var(--color-border-focus);
}

/* ---------------------------------------------------------------------- */
/* Typography
/* ---------------------------------------------------------------------- */

a {
  color: var(--color-text-link);
  text-decoration: none;
}

h1 {
  font-size: 22px;
  margin-bottom: $space-small;
}

h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: math.div($space-small, 2);
}

h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: math.div($space-small, 2);
}

h4 {
  color: var(--color-text-subdued);
  font-size: 12px;
  margin-bottom: math.div($space-small, 2);
  text-transform: uppercase;
}

p {
  font-size: 14px;
}

small {
  font-size: 12px;
}

p,
small {
  > a {
    color: var(--color-text-link);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

a span:hover,
a > p:hover,
a > h3:hover {
  cursor: pointer;
  text-decoration: underline;
}

strong {
  font-weight: 700;
}

hr {
  background-color: var(--color-border-default);
  border: 0;
  height: 1px;
  margin: $space-small 0;
}

button {
  background: none;
  border: none;
  color: inherit;
  font-family: $font-family-mark-web-pro;
  font-size: 14px;
  line-height: 0;
  padding: 0;
}

textarea {
  color: inherit;
  font-family: $font-family-mark-web-pro;
}

::selection {
  background-color: $color-sapphire-50;

  .dark & {
    background-color: $color-sapphire-500;
  }
}

/* ---------------------------------------------------------------------- */
/* Wysiwyg
/* ---------------------------------------------------------------------- */

.wysiwyg {
  * + * {
    margin-top: $space;
  }

  ul {
    list-style-position: outside;
    list-style-type: disc;
    margin-left: $space;

    > li + li {
      margin-top: 0;
    }
  }

  a:hover {
    text-decoration: underline;
  }
}

/* ---------------------------------------------------------------------- */
/* Bootstrap
/* ---------------------------------------------------------------------- */

.sr-only {
  display: none;
}
