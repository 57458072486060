@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Visualizations
/* ---------------------------------------------------------------------- */

.visualizations {
  > * {
    margin-bottom: $space-large;
  }
}

.visualizations-filter {
  display: inline-block;

  & + & {
    margin-left: $space;
  }
}

.visualizations-filter-select {
  min-width: 100%;
}

.visualizations-image-item {
  align-items: center;
  display: flex;

  & + & {
    margin-top: $space-small;
  }

  img {
    height: 50px;
    margin-right: $space-small;
    object-fit: contain;
    width: 50px;
  }
}

.visualizations-list-item {
  list-style-type: disc;
  margin-left: $space;
}
