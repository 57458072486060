@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Loader Page
/* ---------------------------------------------------------------------- */

.loader-page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
}
