@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Madlibs
/* ---------------------------------------------------------------------- */

.madlibs {
  display: flex;
  align-items: center;
  > span {
    padding: math.div($space-small, 2) math.div($space-small, 3);
  }
}

.madlibs-operators,
.madlibs-product-level,
.madlibs-logical-operators {
  margin: math.div($space, 2);
  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  > li + li {
    margin-top: $space-small;
  }
}

.madlibs-product-overlay-popover {
  min-width: 1000px;
}
