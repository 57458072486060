@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Input
/* ---------------------------------------------------------------------- */

.input {
  @extend %input;

  &.is-percentage {
    text-align: right;
  }

  &.has-error {
    border-color: var(--color-text-error);
  }
}

.input-label {
  @extend %label;
  > svg {
    color: var(--color-text-error);
    margin-left: math.div($space-small, 2);
  }
}

.input-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: math.div($space-small, 2);

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}
