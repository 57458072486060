@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Header
* ---------------------------------------------------------------------- */

.header {
  background: $color-neutral-900;
  display: flex;
  height: 55px;

  &.is-data-wave,
  &.is-verified-voices {
    background: linear-gradient(to right, $color-green-100, $color-mint-100);
    color: $color-neutral-700;
    position: relative;

    &:before {
      background-image: url('assets/data-wave-header.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.1;
      position: absolute;
      right: 0;
      top: 0;
    }

    .header-content {
      position: relative;

      > div:first-of-type {
        > a {
          color: $color-neutral-700;
          padding: $space-small 0;

          > img,
          > svg {
            height: 50px;
            margin-right: $space * 2;
          }
        }

        > ul > li {
          display: flex;

          &.is-active {
            border-bottom-color: $color-neutral-900;
          }
        }

        > ul > li > a {
          color: $color-neutral-700;

          &.is-active {
            color: $color-neutral-900;
          }

          &:hover,
          &:focus {
            border-bottom-color: $color-neutral-900;
            color: $color-neutral-900;
          }
        }
      }
    }

    .header-icon-button {
      color: $color-neutral-700;
      cursor: pointer;

      &:hover {
        color: $color-neutral-900;
        font-weight: 700;
      }
    }

    .header-content-account-icon {
      background: $color-neutral-700;
      cursor: pointer;

      > p {
        color: #fff;
      }

      &:hover {
        background: $color-neutral-900;
      }
    }
  }

  &.is-verified-voices:before {
    background-image: url('assets/verified-voices-header.svg');
  }
}

:global(.dark) {
  .header {
    &.is-data-wave,
    &.is-verified-voices {
      background: linear-gradient(to right, $color-neutral-900, $color-sapphire-500);

      &:before {
        opacity: 0.6;
      }

      .header-content {
        > div:first-of-type {
          > a {
            color: #fff;
          }

          > ul > li.is-active {
            border-bottom-color: $color-neutral-50;
          }

          > ul > li > a {
            color: $color-neutral-100;

            &.is-active {
              color: $color-neutral-50;
            }

            &:hover,
            &:focus {
              border-bottom-color: $color-neutral-50;
              color: $color-neutral-50;
            }
          }
        }
      }

      .header-icon-button {
        color: $color-neutral-100;

        &:hover {
          color: $color-neutral-50;
        }
      }

      .header-content-account-icon {
        background: $color-neutral-100;

        > p {
          color: $color-neutral-900;
        }

        &:hover {
          background: $color-neutral-50;
        }
      }
    }
  }
}

.header-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 $space * 2;

  > div:first-of-type {
    display: flex;

    > a {
      align-items: center;
      display: flex;
      padding: $space 0;

      > img {
        height: 100%;
        margin-right: $space-small;
      }

      > div {
        align-items: center;
        color: white;
        display: flex;
        font-size: 24px;
        margin-right: $space * 3;
      }
    }

    > ul {
      display: flex;
      flex: 1;
    }

    > ul > li {
      align-items: center;
      border-bottom: 4px solid transparent;
      border-top: 4px solid transparent;
      color: var(--color-text-faint);
      display: flex;

      &.is-active {
        border-bottom-color: $color-mint-100;
      }
    }

    > ul > li > a {
      color: var(--color-text-faint);
      text-decoration: none;

      &.is-active {
        color: white;
      }

      &:hover,
      &focus {
        border-bottom-color: $color-mint-100;
        color: white;
      }
    }

    > ul > li + li {
      margin-left: 20px;
    }
  }
}

.header-icon-actions {
  align-items: center;
  display: flex;
  gap: $space;
  height: 100%;

  > li {
    display: flex;
  }
}

.header-icon-button {
  color: $color-neutral-100;
  position: relative;

  &:hover {
    color: #fff;
    cursor: pointer;
  }

  svg {
    height: $icon-size;
    width: $icon-size;
  }
}

.header-content-account-icon {
  align-items: center;
  background-color: $color-neutral-100;
  border-radius: 50%;
  color: $color-neutral-900;
  display: flex;
  height: $icon-size;
  line-height: 1;
  justify-content: center;
  width: $icon-size;

  &:hover {
    background-color: #fff;
    cursor: pointer;
  }

  p {
    user-select: none;
  }
}

.header-notification-badge {
  background-color: var(--color-text-error);
  border-radius: 50%;
  height: $space-small;
  position: absolute;
  right: 0;
  top: 0;
  width: $space-small;
}
