@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Table List
/* ---------------------------------------------------------------------- */

.table-list {
  margin-bottom: $space-large * 2;
}

.table-list-thead {
  color: var(--color-text-subdued);
  overflow-x: hidden;
  overflow-y: auto;
  position: sticky;
  text-transform: uppercase;
  top: 0;
  white-space: nowrap;
  z-index: 10;
}

.table-list-tbody {
  overflow-x: auto;
  overflow-y: hidden;
}

.table-list-th,
.table-list-td {
  padding: $space-small;
  position: relative;

  &:first-of-type > span {
    border-right: 1px solid var(--color-border-default);
  }

  &:nth-child(n + 3) {
    justify-content: flex-end;
    text-align: right;
  }

  &:nth-child(2) > span {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
    > a {
      color: var(--color-text-default);
    }
    > span > svg {
      color: var(--color-icon-default);
      margin-bottom: -2px;
      margin-right: $space-small;
    }
  }

  &:not(:last-child) > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 1;
  }
}

.table-list-tr:not(.is-loading):hover .table-list-td:before,
.table-list-tr.is-active .table-list-td:before {
  background-color: var(--color-surface-light);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.table-list-tr.is-expanded {
  .table-list-td:first-of-type {
    max-width: 45px;
  }

  .table-list-td:nth-child(2) {
    flex-grow: 1;
  }

  &:hover .table-list-td:before {
    content: none;
  }
}

.table-list-tr.is-disabled > .table-list-td {
  > *:not(div) {
    opacity: 0.5;
    pointer-events: none;
  }
}

.table-list-tr:hover {
  .table-list-td-actions {
    display: flex;
    position: relative;
  }
  .table-list-td-hidden {
    visibility: hidden;
  }
}

.table-list-td-actions {
  display: none;
}

.table-list-th {
  background-color: var(--color-surface-default);
  border-bottom: 1px solid var(--color-border-default);
  display: flex;
  padding: $space-small;
  text-align: left;
  vertical-align: inherit;

  &.is-sortable:hover {
    color: var(--color-text-default);
  }

  p {
    font-size: 12px;
  }
}

.table-list-td {
  align-items: center;
  border-bottom: 1px solid var(--color-border-subdued);
  display: flex;

  span + span {
    margin-left: $space-small;
  }
}

.table-list-expand-icon {
  margin-left: auto;
  overflow: visible !important;
  position: relative;
  width: $space;
  z-index: 5;

  &.is-active {
    &:before {
      background-color: var(--color-text-default);
      border-radius: 50%;
      bottom: math.div(-$space-small, 2);
      content: '';
      left: math.div(-$space-small, 2);
      position: absolute;
      right: math.div(-$space-small, 2);
      top: math.div(-$space-small, 2);
      z-index: -1;
    }
  }

  > svg {
    color: var(--color-text-inverse);
    display: block;
    height: $space;
    line-height: 1;
    width: $space;
    z-index: 10;
  }
}

.table-list-empty-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $space-large * 4;
}

/* ---------------------------------------------------------------------- */
/*  Table List Pagination
/* ---------------------------------------------------------------------- */

.table-list-pagination {
  margin: $space-small * 2 0 $space * 4;
}
