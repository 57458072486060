@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* ITEM_COLUMNS
/* ---------------------------------------------------------------------- */

.pre-data-wave {
  svg {
    color: var(--color-icon-info);
  }
}
