@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Dashboard
/* ---------------------------------------------------------------------- */

.dashboard {
  margin: $space * 2 auto;
  max-width: $max-width;
  padding: 0 $space;
}

.dashboard-body {
  flex-grow: 1;
}

.dashboard-proxy {
  margin-bottom: 0;

  button {
    color: var(--color-text-link);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.dashboard-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
