/* ---------------------------------------------------------------------- */
/* Themes: Root (Light)
/* ---------------------------------------------------------------------- */

:root {
  // Borders
  --color-border-active: #{$color-neutral-900};
  --color-border-default: #{$color-neutral-100};
  --color-border-focus: #{$color-neutral-300};
  --color-border-subdued: #{$color-neutral-75};

  // Buttons
  --color-button-error-default: #{$color-red-100};
  --color-button-error-disabled: #{$color-neutral-50};
  --color-button-error-hover: #{$color-red-300};
  --color-button-error-pressed: #{$color-red-500};

  --color-button-primary-default: #{$color-sapphire-100};
  --color-button-primary-disabled: #{$color-neutral-50};
  --color-button-primary-hover: #{$color-sapphire-300};
  --color-button-primary-pressed: #{$color-sapphire-500};

  --color-button-secondary-default: #fff;
  --color-button-secondary-disabled: #fff;
  --color-button-secondary-hover: #{$color-neutral-75};
  --color-button-secondary-pressed: #{$color-neutral-100};

  --color-button-tertiary-default: #{$color-neutral-700};
  --color-button-tertiary-disabled: #{$color-neutral-50};
  --color-button-tertiary-hover: #{$color-sapphire-500};
  --color-button-tertiary-pressed: #{$color-neutral-900};

  // Elevation
  --color-elevation-rgb: 0, 0, 0;

  // Icons
  --color-icon-default: #{$color-neutral-100};
  --color-icon-hover: #{$color-sapphire-100};
  --color-icon-pressed: #{$color-sapphire-100};

  --color-icon-danger: #{$color-red-100};
  --color-icon-feedback: #{$color-blue-75};
  --color-icon-info: #{$color-blue-200};
  --color-icon-success: #{$color-green-100};
  --color-icon-warning: #{$color-yellow-100};

  --size-icon: #{$icon-size};
  --size-tag: #{$tag-size};

  // Modals
  --color-modal-backdrop: #fff;
  --opacity-modal-backdrop: 0.5;

  // Surfaces
  --color-surface-blue: #{$color-blue-50};
  --color-surface-default: #fff;
  --color-surface-light: #{$color-neutral-50};
  --color-surface-medium: #{$color-neutral-75};
  --color-surface-inverse: #{$color-neutral-900};

  // Text
  --color-text-default: #{$color-neutral-900};
  --color-text-faint: #{$color-neutral-100};
  --color-text-subdued: #{$color-neutral-300};

  --color-text-brand: #{$color-sapphire-100};
  --color-text-error: #{$color-red-100};
  --color-text-inverse: #fff;
  --color-text-link: #{$color-sapphire-100};
}
