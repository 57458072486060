@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Metadata
/* ---------------------------------------------------------------------- */

.metadata {
  width: percentage(2/5);

  > ul {
    display: table;
    width: 100%;
  }

  > ul > li {
    display: table-row;
  }

  > ul > li + li > span {
    border-top: 1px solid var(--color-border-subdued);
    margin-top: $space-small;
  }

  > ul > li > span {
    display: table-cell;
    padding: math.div($space-small, 2) 0;

    &:first-of-type {
      padding-right: $space-large;
    }

    &:last-of-type {
      margin-left: $space;
      text-align: right;
      white-space: nowrap;
    }
  }
}
