@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Public 
/* ---------------------------------------------------------------------- */

.public {
  display: flex;
  flex-direction: column;

  > span {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    > div {
      flex-grow: 1;
    }

    input {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    button {
      margin-left: $space;
    }
  }
}
