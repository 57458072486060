@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Instant Survey Question
/* ---------------------------------------------------------------------- */

.modal-instant-survey-question {
  display: grid;
  row-gap: $space;

  [data-rbd-draggable-id] {
    // fix for draggable items inside a modal
    left: auto !important;
    top: auto !important;
  }
}

.modal-instant-survey-question-actions {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: $space-small;

  svg {
    color: var(--color-text-faint);
  }
}

.modal-instant-survey-question-answers-li {
  margin-bottom: $space;
}

.modal-instant-survey-question-text-and-type {
  display: flex;
  flex-direction: row;
  gap: $space-small;

  > div:first-of-type {
    flex: 1;
    width: 625px;
  }
  > div:last-of-type {
    flex-shrink: 0;
    width: 300px;
  }
}

.modal-instant-survey-question-select {
  display: block;
  min-width: auto;
}

.modal-instant-survey-question-randomize {
  margin: math.div($space-small, 2) 0 $space;
}

.modal-instant-survey-question-input {
  resize: none;
  width: 100%;
}

.modal-instant-survey-question-none,
.modal-instant-survey-question-other,
.modal-instant-survey-question-other-placeholder {
  padding-top: $space-small;
}

.modal-instant-survey-question-options {
  border-top: 1px dashed var(--color-border-default);
  margin-top: $space;
  padding-left: $space + $space-small;
  padding-top: $space;
}

.modal-instant-survey-question-other {
  margin-right: $space-small;
  white-space: nowrap;
}

.modal-instant-survey-question-other-placeholder {
  font-style: italic;
}
