@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Date Picker (shared w/ Date Range Picker)
/* ---------------------------------------------------------------------- */

$border-size: 2px;

%date-picker {
  > h4 {
    margin-bottom: math.div($space-small, 4);
  }

  > div {
    background-color: var(--color-surface-light);
    border: 1px solid var(--color-border-default);
    border-radius: $border-radius;
    display: inline-block;
    height: $button-height;
  }

  &.is-disabled > div {
    background-color: var(--color-surface-light);
    border-color: var(--color-border-default);
    opacity: 0.5;
    pointer-events: none;
  }

  :global(.react-datepicker) {
    background-color: var(--color-surface-default);
    border: 0;
    box-shadow: $elevation-300;
    font-family: $font-family-mark-web-pro;
    padding: $space-small;
  }

  :global(.react-datepicker__current-month) {
    color: var(--color-text-default);
  }

  :global(.react-datepicker-wrapper) {
    width: auto;

    &::placeholder {
      color: var(--color-text-subdued);
    }

    &:disabled {
      background-color: var(--color-surface-light);
      border-color: var(--color-border-default);
      opacity: 0.5;
      pointer-events: none;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: $border-size solid transparent;
      color: var(--color-text-default);
      font-family: $font-family-mark-web-pro;
      font-size: inherit;
      padding: $space-small $space-small $space-small - $border-size $space-small;
      width: 135px;

      &:focus {
        border-bottom-color: $color-sapphire-100;
        outline: none;

        &::placeholder {
          color: var(--color-text-faint);
        }
      }
    }
  }

  :global(.react-datepicker__tab-loop) {
    display: inline-block;
  }

  :global(.react-datepicker-popper) {
    padding-top: $space-large;
  }

  :global(.react-datepicker__navigation) {
    border: 1px solid var(--color-border-subdued);
    line-height: 1;
    margin: $space;
    top: 0;
  }

  :global(.react-datepicker__navigation-icon:before) {
    border-width: 1px 1px 0 0;
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
    border-bottom: none;
    padding-bottom: 0;
    padding-top: $space-small;
  }

  :global(.react-datepicker__triangle:after) {
    border-bottom-color: var(--color-surface-default) !important;
  }

  :global(.react-datepicker__triangle:before) {
    border-bottom-color: var(--color-border-subdued) !important;
  }

  :global(.react-datepicker__day-names) {
    margin-top: $space;
  }

  :global(.react-datepicker__day-name) {
    color: var(--color-text-subdued);
    line-height: 2.25rem;
    width: 2.25rem;
  }

  :global(.react-datepicker__day) {
    border-radius: 0;
    color: var(--color-text-default);
    line-height: 2.25rem;
    width: 2.25rem;

    &:hover {
      background-color: var(--color-surface-light);
    }
  }

  :global(.react-datepicker__day--disabled) {
    cursor: not-allowed;
  }

  :global(.react-datepicker__day--outside-month) {
    visibility: hidden;
  }

  :global(.react-datepicker__day--today) {
    font-weight: 400;

    &:hover {
      color: #fff;
    }
  }

  :global(.react-datepicker__day--in-selecting-range),
  :global(.react-datepicker__day--in-range) {
    background-color: var(--color-button-primary-default);
  }

  :global(.react-datepicker__day--selected),
  :global(.react-datepicker__day--selecting-range-start),
  :global(.react-datepicker__day--selecting-range-end) {
    background-color: var(--color-button-primary-hover);

    &:hover {
      background-color: var(--color-button-primary-hover);
    }
  }
}

%date-picker-close {
  color: var(--color-text-faint);
  cursor: pointer;
  display: inline-block;
  margin: 0 $space;
  min-width: 0;
  visibility: hidden;
  width: $space;

  &:hover path {
    color: var(--color-text-default);
  }

  &.is-visible {
    visibility: visible;
  }
}

.date-picker {
  @extend %date-picker;
}

.date-picker-close {
  @extend %date-picker-close;
}
