@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Search
/* ---------------------------------------------------------------------- */

.search {
	margin-bottom: $space-large;

	> div > div,
	input {
		width: 100%;
	}
}
