@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Group Join
/* ---------------------------------------------------------------------- */

.join {
  input {
    margin-bottom: $space;
    width: 100%;
  }
}

.join-content {
  align-items: center;
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  display: flex;
  margin-top: $space;
  padding: $space 0;
}

.join-description {
  margin-bottom: $space;
}

.join-order {
  flex: 2;
  padding: $space $space math.div($space, 2) $space;
  > h3 {
    color: var(--color-text-brand);
    padding-left: $space-small;
  }
}

.join-order-groups {
  display: flex;
}

.join-type {
  flex: 1;
  padding: $space $space math.div($space, 2) $space;
  > h3 {
    color: var(--color-text-brand);
  }
  > ul {
    padding: math.div($space-small, 2) 0;
    width: 100%;

    > li + li {
      margin-top: math.div($space, 3);
    }
  }
}

.join-type-icon {
  align-items: center;
  display: flex;
  margin-right: $space-small;

  > svg {
    height: 34px;
    width: 34px;
  }
}
