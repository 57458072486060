@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Comparison Charts
/* ---------------------------------------------------------------------- */

.comparison-charts {
  > hr {
    margin: 0;
  }
}
