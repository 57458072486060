@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Tooltip
/* ---------------------------------------------------------------------- */

.tooltip:global(.tooltip) {
  --bs-tooltip-arrow-height: #{$space-small}; // spacing from icon
  --bs-tooltip-arrow-width: #{$space-small};
  --bs-tooltip-bg: #{$color-neutral-900}; // eventually var(--color-surface-inverse)
  --bs-tooltip-border-radius: #{$border-radius};
  --bs-tooltip-color: #fff; // eventually var(--color-text-inverse)
  --bs-tooltip-font-size: 12px;
  --bs-tooltip-max-width: 300px;
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-padding-x: #{$space-small};
  --bs-tooltip-padding-y: #{$space-small};
  --bs-tooltip-zindex: 1060; // popover z-index is 1070, default tooltip z-index is 1080
  pointer-events: none;

  :global(.tooltip-arrow) {
    display: none;
  }

  :global(.tooltip-inner) {
    display: inline-block;
    text-align: left;
  }

  &.is-full-width {
    :global(.tooltip-inner) {
      max-width: none;
    }
  }
}
