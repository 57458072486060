@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Mentions
/* ---------------------------------------------------------------------- */

.mentions--multiLine .mentions__control {
  min-height: $space * 3;

  & ~ div {
    background: none !important;
    margin-top: 0 !important;
  }
}

.mentions--multiLine .mentions__highlighter {
  min-height: $space-large * 3;
  padding: $space-small;
}

.mentions--multiLine .mentions__input {
  border: 1px solid transparent;
  outline: 0;
  padding: $space-small;

  &:focus::placeholder {
    color: var(--color-text-faint);
  }
}

.mentions__highlighter {
  strong {
    background-color: var(--color-surface-light);
    border-radius: $border-radius;
  }
}

.mentions__suggestions__list {
  background-color: var(--color-surface-default);
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  margin-top: $space-large !important;
}

.mentions__suggestions__item {
  padding: math.div($space-small, 2) $space;
}

.mentions__suggestions__item--focused {
  background-color: var(--color-surface-light);
  color: var(--color-text-default);
}
