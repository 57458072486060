@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Missing Required Prompts 
/* ---------------------------------------------------------------------- */

.modal-missing-required-prompts {
  ul {
    margin-top: $space-large;
  }

  li + li {
    margin-top: $space;
  }
}
