@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question
/* ---------------------------------------------------------------------- */

.question-actions {
  align-items: center;
  display: inline-flex;
  height: $space-large * 2;
}

.question-answer {
  display: flex;
  gap: $space-small;

  > span {
    word-break: break-word;
  }

  > svg {
    height: $pill-icon-size;
    margin-top: 2px;
    width: $pill-icon-size;
  }
}

.question-answer-image {
  margin-bottom: $space-small;
  max-height: 125px;
  max-width: 200px;
}

.question-answer-body {
  display: flex;
  flex-flow: column;
  gap: math.div($space-small, 2);
}

.question-body {
  flex-grow: 1;
  padding: math.div($space-small, 2) 0;
}

.question-card {
  background: var(--color-surface-default);
  border: 1px solid var(--color-border-subdued);
  border-radius: $border-radius;
  box-shadow: $box-shadow-shallow;
  gap: $space;
  padding: math.div($space-small, 2) $space-small;
  width: $min-width * 0.75;

  & > div:first-child {
    display: inline-flex;
  }
}

.question-detailed-view {
  color: var(--color-text-default);
}

.question-drag-handle {
  align-items: center;
  color: var(--color-text-faint);
  display: flex;
  height: $space-large * 2;
}

.question-draggable-container {
  margin-bottom: $space * 2;
}

.question-image {
  max-height: 125px;
  max-width: 200px;
}

.question-number {
  align-items: center;
  display: inline-flex;
  font-weight: $font-weight-bold;
  height: $space-large * 2;
  min-width: $space-large * 2;
  padding: $space-small;
}

.question-open-ended {
  font-style: italic;
}

.question-pill-wrapper {
  display: inline-flex;
  flex-shrink: 0;
  padding: 4px 0;
}

.question-text {
  color: var(--color-text-default);
  flex-grow: 1;
  padding: 4px 0;
  word-break: break-word;
}
