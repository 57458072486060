@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Favorite Toggle
/* ---------------------------------------------------------------------- */
.is-favorite {
  svg {
    color: $color-yellow-100 !important;
    width: $space;
    height: $space;
  }
}
