@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* SSO
/* ---------------------------------------------------------------------- */

.sso {
  > h1 {
    color: var(--color-text-subdued);
    font-size: 25px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 auto;
    width: 90%;
  }

  > small {
    margin-bottom: $space * 2;
    margin-top: math.div(-$space-small, 2);
  }
}

.sso-action {
  color: var(--color-text-link);

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
