@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  ActionArea
/* ---------------------------------------------------------------------- */

.action-area {
  padding-bottom: ($space-large * 2) + 42px; // account for height of the footer
  position: relative;
}

.action-area-footer {
  background-color: transparent;
  bottom: 0;
  left: 0;
  padding: $space-large 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  z-index: 35;

  button {
    pointer-events: auto;
  }
}

.action-area-footer-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: calc(100vw - #{2 * $space});
  position: relative;

  @media (min-width: $max-width) {
    max-width: $max-width - (2 * $space);
  }
}
