@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Responses
/* ---------------------------------------------------------------------- */

.responses {
  padding-bottom: $space-large;

  > h3 {
    margin-bottom: $space;
  }
}

.responses-compare-by {
  ul {
    margin-bottom: 0;
  }
}

.responses-filter-error {
  color: var(--color-text-subdued);
  font-size: 12px;
  margin: 0;
}

.responses-filter-group {
  margin-bottom: $space-large;
}

.responses-filter-reset {
  align-items: center;
  padding: 0;
}

.responses-filter-select {
  display: block;
  margin-bottom: $space;
  min-width: auto;
}

.responses-main {
  padding-top: $space-large;

  > hr {
    margin: 0;
  }
}

.responses-question-text {
  color: var(--color-text-brand);
  margin-bottom: $space-small;
}

.responses-sidebar {
  border-right: 1px solid var(--color-border-subdued);
  flex-shrink: 0;
  max-height: 100vh;
  overflow-y: auto;
  padding: $space-large (2 * $space) (2 * $space-large) 0;
  position: sticky;
  top: 0;
}
