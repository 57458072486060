@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Answer
/* ---------------------------------------------------------------------- */

.answer {
  align-items: flex-start;
  display: flex;
  gap: $space-small;
  flex: 1;
}

.answer-types {
  color: var(--color-text-faint);
  flex-shrink: 0;
  padding-top: $space-small;
  padding-right: $space-small;
  svg {
    height: $space-small * 2;
    width: $space-small * 2;
    &:not(:last-child) {
      margin-right: $space-small;
    }
  }
  &::after {
    background: var(--color-border-subdued);
    content: '';
    height: $space-small * 2;
    margin-left: $space-small;
    margin-top: $space-small;
    position: absolute;
    transform: translateY(-$space-small);
    width: 1px;
  }
}

.answer-controls {
  flex-shrink: 0;
  padding-top: math.div($space-small, 3);
  position: relative;
}

.answer-drag-icon {
  color: var(--color-icon-default);
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  line-height: 0;
  padding-top: $space-small + math.div($space-small, 3);
  width: $space;
}

.answer-input-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  min-width: 0;

  > div {
    width: 100%;
  }
}

.answer-type-icon {
  border-radius: $border-radius;
  color: var(--color-icon-default);
  flex-shrink: 0;
  line-height: 0;
  overflow: hidden;
  padding-top: math.div($space, 2);
}
