@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* WhatsNext Tab
/* ---------------------------------------------------------------------- */

.whats-next-tab-text {
  > a {
    color: var(--color-text-default);
    text-decoration: underline;
  }
}
