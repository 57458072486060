@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Insights
/* ---------------------------------------------------------------------- */

.insights-charts-and-table {
  > div + div {
    margin-top: $space-large;
  }
}

.insights-global-filters-and-legend {
  border-right: 1px solid var(--color-border-subdued);
  padding-right: $space;
  padding-top: $space-large;
  position: sticky;
  top: 0;
  z-index: 10;

  > div + div {
    margin-top: $space-small;
  }

  > div > div {
    min-width: 0;
    width: 100%;
  }
}
