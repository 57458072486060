@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Minus Plus Buttons
/* ---------------------------------------------------------------------- */
.minus-plus-buttons {
  display: flex;
  margin-left: $space-small;
  text-align: left;
  width: $space-large * 2;

  button {
    border-radius: $border-radius;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &:focus-visible {
      @extend %outline-offset;
    }
  }

  button + button {
    margin-left: $space-small/2;
  }
}

.button-minus-icon {
  color: var(--color-text-error);

  &.is-neutral {
    color: var(--color-text-default);
  }
}

.button-plus-icon {
  color: var(--color-text-subdued);

  &:disabled {
    color: var(--color-text-faint);
  }

  &.is-neutral {
    color: var(--color-text-default);
  }
}
