@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Module List Items
/* ---------------------------------------------------------------------- */

.module-list-items {
  display: flex;
  flex-wrap: wrap;
  margin-top: $space * 3;

  > li {
    display: flex;
    flex: 0 0 percentage(1/3);
    padding-right: $space * 2;
  }

  > li.is-locked {
    opacity: 0.5;
    pointer-events: none;
  }

  > li:nth-child(n + 4) {
    margin-top: $space * 3;
  }

  > li > svg {
    color: var(--color-text-default);
    height: 44px;
    margin-right: $space;
    max-width: 44px;
    width: 100%;
  }

  small {
    color: var(--color-text-error);
    display: inline-block;
    text-transform: uppercase;
    margin-top: math.div($space-small, 2);
  }
}

.module-list-items-title {
  display: flex;
  > a,
  > span {
    align-items: center;
    display: flex;
    > button > span > svg {
      color: var(--color-icon-default);
      height: $space;
      margin-left: math.div($space-small, 3);
      width: $space;
      &:hover {
        color: var(--color-text-link);
        cursor: pointer;
      }
    }
    > i > svg {
      color: var(--color-icon-default);
      margin-bottom: math.div(-$space-small, 2);
      margin-left: math.div($space-small, 3);
    }
    > svg {
      height: $space;
      margin-left: $space-small;
      width: $space;
    }
  }
}
