@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Thought Leadership
/* ---------------------------------------------------------------------- */
.thought-leadership {
  > ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;

    > li {
      flex: 0 0 calc((100% - #{$space}) / 2);
      margin-top: math.div($space-small, 2);
    }

    > li:nth-child(even) {
      margin-left: $space;
    }

    > li:nth-child(n + 3) {
      margin-top: $space;
    }

    img {
      width: 100%;
    }

    small {
      color: var(--color-text-subdued);
      display: block;
      margin: math.div($space-small, 2) 0;
      text-transform: uppercase;
    }
  }
}
