@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Radio
/* ---------------------------------------------------------------------- */

.radio {
  align-items: center;
  display: flex;
  position: relative;

  > input {
    display: none;
  }

  .radio-icon {
    color: var(--color-icon-default);
    line-height: 0;
    margin-right: $space-small;
  }

  > label {
    align-items: center;
    display: flex;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;

    &:hover {
      text-decoration: underline;
    }

    > span:last-of-type {
      color: var(--color-text-faint);
      left: calc(100% + #{$space-small} / 2);
      position: absolute;
      white-space: nowrap;
    }
  }

  &.is-checked .radio-icon {
    color: var(--color-text-link);
  }
}
