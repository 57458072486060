@use 'sass:math';
@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Export Menu
/* ---------------------------------------------------------------------- */

.export-menu {
  display: flex;
  justify-content: flex-end;
  margin-bottom: math.div($space-small, 2);
}
