@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Prompt Search
/* ---------------------------------------------------------------------- */

.modal-prompt-search-bar {
  display: block;
  margin-bottom: $space-large;

  > div > div,
  input {
    width: 100%;
  }
}

.modal-prompt-search-results {
  display: flex;
  gap: $space;
  justify-content: space-between;
  min-height: $space * 30;

  > div:first-child {
    flex-grow: 1;
  }

  > div:last-child {
    align-self: flex-start;
    flex-shrink: 0;
    width: 300px;
  }
}
