@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Freeze
/* ---------------------------------------------------------------------- */

.freeze-content {
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: $space;
  }

  :global(.select__control) {
    width: 100%;
  }

  input {
    width: 100%;
  }
}

.freeze-select {
  width: 100%;
}
