@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Statement
/* ---------------------------------------------------------------------- */

.statement-condition,
.statement-operator {
  > button,
  > span > button {
    cursor: default;
    margin: math.div($space-small, 2);
  }
}
