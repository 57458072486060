@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Toolbar
/* ---------------------------------------------------------------------- */

.toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.toolbar-tools {
  display: flex;
  gap: $space-small;
}

.toolbar > div {
  flex: 1;
}

.toolbar-tools {
  flex-grow: 0;

  a,
  button {
    color: var(--color-icon-default);
    cursor: pointer;

    &:not(:disabled) {
      &:hover {
        color: var(--color-icon-hover);
      }
      &:active {
        color: var(--color-icon-pressed);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    span:not(:first-child) {
      margin-left: 5px;
      vertical-align: super;
    }
  }

  svg {
    height: $space-large;
    width: $space-large;
  }
}
