@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question Logic Options
/* ---------------------------------------------------------------------- */

.question-logic-options-label {
  color: var(--color-text-subdued);
  font-size: 12px;
  text-transform: uppercase;
}

.question-logic-options-list {
  > li {
    margin-top: $space-small;
  }

  label {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > div {
      display: inline-block;
      position: relative;
      top: 2px;
    }
  }
}

.question-logic-options-overlay {
  padding: $space;
}

.question-logic-options-overlay-error {
  color: var(--color-text-error);

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}

.question-logic-options-pill-focus {
  outline: 2px solid var(--color-border-focus);
}
