@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Breadcrumbs
/* ---------------------------------------------------------------------- */

.breadcrumbs {
  align-items: center;
  display: flex;
  gap: math.div($space-small, 2);
  margin-bottom: $space;

  > li {
    align-items: center;
    display: inline-flex;
    gap: math.div($space-small, 2);
  }

  > li > svg {
    color: var(--color-icon-default);
    height: 16px;
    width: 16px;
  }

  > li > a,
  > li > button,
  > li > small {
    color: var(--color-text-subdued);
    font-family: $font-family-mark-web-pro;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    text-transform: uppercase;
  }

  > li > a:hover,
  > li > button:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
