@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Accordion
/* ---------------------------------------------------------------------- */

.accordion {
  border-bottom: 1px solid var(--color-border-default);
  padding-bottom: math.div($space-small, 2);
  position: relative;

  h6 {
    line-height: 0;
    margin: 0;
  }

  & + & {
    margin-top: $space-small;
  }
}

.accordion-button {
  align-items: center;
  color: var(--color-text-link);
  cursor: pointer;
  display: inline-flex;
  gap: math.div($space-small, 2);
  height: 20px;
  line-height: 20px;

  &:focus-visible {
    @extend %outline-offset;
  }
}

.accordion-chevron {
  display: inline-flex;
  svg {
    height: 16px;
    width: 16px;

    [aria-expanded='true'] & {
      transform: rotate(180deg);
    }
  }
}

.accordion-icon {
  color: var(--color-icon-default);
  display: inline-flex;

  svg {
    height: 20px;
    width: 20px;
  }
}

.accordion-region {
  margin: $space 0 math.div($space-small, 2);
  position: relative;

  &[hidden] {
    display: none;
  }
}
