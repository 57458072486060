@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Questions Library
/* ---------------------------------------------------------------------- */

.modal-questions-library-list {
  height: 316px;
  overflow: auto;
}

.modal-questions-library-search {
  display: block;
  margin-bottom: 2 * $space;
  margin-top: $space-small;

  input {
    width: 100%;
  }
}
