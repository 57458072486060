@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Date Options
/* ---------------------------------------------------------------------- */

.date-options-list {
  margin-bottom: $space-large;

  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  > li > div + div {
    margin: $space-small 0 0 $space-small * 2.5;
  }

  > li + li {
    margin-top: $space-small;
  }

  > li > span {
    line-height: 1;
    margin-left: math.div($space, 2);

    svg {
      color: var(--color-text-default);
      height: $space;
    }
  }
}

.date-options-select-container {
  display: flex;
  align-items: center;
  z-index: 1;

  span {
    margin-left: $space-small;

    svg {
      display: block;
    }
  }
}
