@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Rich Text Input
/* ---------------------------------------------------------------------- */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mentions__control {
  padding: $space-small;
}

.mentions__highlighter {
  border: 0 !important;
  line-height: initial;

  strong {
    background-color: var(--color-surface-light);
    border-radius: $border-radius;
  }
}

.mentions__suggestions {
  background-color: var(--color-surface-default) !important;
  border-radius: $border-radius;
  box-shadow: $elevation-300;
  transform: translateY($space-large);
}

.mentions__suggestions__item {
  color: var(--color-text-default);
  padding: math.div($space-small, 2) $space-small;
}

.mentions__suggestions__item--focused {
  background-color: var(--color-surface-light);
}

.rich-text-input {
  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  input[type='text'] {
    background-color: transparent;
    border: 0;
    color: var(--color-text-default);
    font-family: $font-family-mark-web-pro;
    font-size: inherit;
    outline: none;
    padding: $space-small;
    width: 100%;

    &::placeholder {
      color: var(--color-text-subdued);
    }
  }
}

.rich-text-input-container {
  background-color: var(--color-surface-light);
  border-radius: $border-radius;
  border: 1px solid var(--color-border-default);
  min-height: $button-height;

  &.is-focused {
    background-color: var(--color-surface-default);
    border-color: var(--color-border-focus);

    input[type='text']::placeholder {
      color: var(--color-text-subdued);
    }
  }
}

.rich-text-input-divider {
  border-bottom: 1px solid var(--color-border-subdued);
  margin: 0 $space-small;
}

.rich-text-input-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: math.div($space-small, 2);

  &:before {
    background-color: var(--color-text-error);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}

.rich-text-input-file {
  display: none;
}

.rich-text-input-image-container {
  padding: $space-small;
}

.rich-text-input-image-error {
  align-items: center;
  background-color: $color-red-100;
  border-radius: $border-radius;
  color: #fff;
  display: flex;
  height: $space * 3;
  justify-content: space-between;
  padding: $space-small;
}

.rich-text-input-image-error-close-icon {
  cursor: pointer;
  margin-right: $space-small;

  > svg {
    height: $space-small;
    width: $space-small;
  }
}

.rich-text-input-image-error-text {
  align-items: center;
  display: flex;
  gap: $space-small;

  > svg {
    height: $space-small * 2;
    width: $space-small * 2;
  }
}

.rich-text-input-image-loading {
  align-items: center;
  background-color: var(--color-surface-light);
  border-radius: $border-radius;
  display: flex;
  gap: $space-small;
  height: $space * 3;
  padding: $space-small;

  > svg {
    animation: spin 1s linear infinite;
  }
}

.rich-text-input-image-preview {
  height: fit-content;
  position: relative;
  width: fit-content;

  &:hover {
    & > .rich-text-input-remove-image-container {
      visibility: visible;
    }
  }

  > img {
    max-width: 200px;
    max-height: 125px;
  }
}

.rich-text-input-image-preview-overlay {
  background-color: var(--color-text-default);
  left: 0;
  height: calc(100% - #{$space-small / 2});
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.rich-text-input-label {
  @extend %label;
}

.rich-text-input-remove-image-button {
  align-items: center;
  background-color: var(--color-text-default);
  border-radius: math.div($icon-size, 2);
  color: var(--color-surface-default);
  cursor: pointer;
  display: flex;
  height: $icon-size;
  justify-content: center;
}

.rich-text-input-remove-image-container {
  position: absolute;
  right: math.div(-$icon-size, 2);
  top: math.div(-$icon-size, 2);
  visibility: hidden;
  width: $icon-size;
  z-index: 1;

  &:hover {
    & + .rich-text-input-image-preview-overlay {
      opacity: 0.25;
    }
  }
}

.rich-text-input-remove-image-icon {
  height: math.div($icon-size, 2);
  width: math.div($icon-size, 2);

  & > line {
    stroke-width: 3px;
  }
}

.rich-text-input-tools {
  display: flex;
  gap: $space-small;
  line-height: 0;
  padding: $space-small;

  button,
  [role='button'] {
    align-items: center;
    color: var(--color-text-subdued);
    display: inline-flex;
    gap: math.div($space-small, 2);
    height: 32px;
    justify-content: center;
    line-height: initial;
    min-width: 32px;

    &:hover {
      color: var(--color-text-link);
      cursor: pointer;
    }
  }
}
