@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Recent Reports
/* ---------------------------------------------------------------------- */

.recent-reports {
  > ul {
    padding-right: $space * 2;
  }

  > ul > li {
    margin-bottom: math.div($space-small, 2);
  }

  > ul > li > span {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
