@use 'sass:math';
@import '../../variables';

/* ---------------------------------------------------------------------- */
/*  Selectable Card
/* ---------------------------------------------------------------------- */

.selectable-card {
  transition: opacity $transition-duration linear;

  &.is-active {
    border: 2px solid var(--color-text-link);
    box-shadow: none;
    padding: ($space - 2);
  }

  &.is-clickable {
    cursor: pointer;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
  }

  &.is-clickable[aria-disabled='true'] {
    cursor: not-allowed;
    user-select: none;
  }
}

.selectable-card-content {
  display: flex;
  flex-direction: row;
  gap: $space-small;

  > div:last-of-type {
    flex: 1;
  }
}

.selectable-card-details {
  color: var(--color-text-subdued);
  margin-top: math.div($space-small, 2);
}

.selectable-card-error {
  color: var(--color-text-error);
  margin-top: math.div($space-small, 2);

  &::before {
    background: var(--color-text-error);
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: $space-small;
    margin-right: $space-small;
    width: $space-small;
  }
}

.selectable-card-header {
  font-weight: bold;
  margin-bottom: $space-small;
}

.selectable-card-helper-text {
  margin-bottom: $space-small;
}

.selectable-card-icon {
  align-items: center;
  color: var(--color-text-faint);
  display: flex;
  flex-grow: 0;
  height: $icon-size;
  justify-content: center;
  width: $icon-size;

  .is-active & {
    color: var(--color-text-link);
  }
}
