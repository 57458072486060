@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Review
/* ---------------------------------------------------------------------- */

.review {
  display: flex;
  flex-direction: column;
  gap: 2 * $space;
}

.review-actions {
  margin-top: $space-small;
  text-align: right;
}

.review-announcement {
  margin-bottom: 0 !important;
}

.review-card {
  display: flex;
  flex-direction: column;
  gap: 2 * $space;

  hr {
    margin: 0;
  }
}

.review-column {
  &:first-child {
    flex: 1;
  }
  &:last-child {
    width: 500px;
  }
}

.review-column-layout {
  display: flex;
  flex-direction: row;
  gap: $space;
}

.review-credits-card {
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: $space-small;

  h4 {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.review-credits-warning {
  border-top: 1px dashed var(--color-border-default);
  padding-top: $space-small;
}

.review-detail-description {
  color: var(--color-text-subdued);
  font-size: 12px;
}

.review-details-list {
  > li:not(:last-of-type) {
    margin-bottom: $space-small;
  }
}

.review-group {
  display: flex;
  flex-direction: column;
  gap: $space;

  > div:first-child {
    margin: 0;
  }
}

.review-group-icon {
  color: var(--color-icon-success);
}

.review-group-icon-error {
  color: var(--color-icon-danger);
}

.review-group-icon-warning {
  color: var(--color-icon-warning);
}

.review-question-list {
  list-style-type: decimal;
  padding-left: $space;

  > li:not(:first-child) {
    margin-top: math.div($space-small, 2);
  }

  > li > div {
    display: flex;
    gap: math.div($space-small, 2);

    > i + i {
      margin-left: 0 !important;
    }
  }
}

.review-questions {
  border-bottom: 0;
}

.review-section {
  display: flex;
  flex-direction: column;
  gap: $space;

  h3,
  h5 {
    margin-bottom: 0;

    button {
      color: var(--color-text-link);
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
