@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* AttributeList
/* ---------------------------------------------------------------------- */

.attribute-list {
  > li {
    align-items: center;
    display: flex;
  }

  > li + li {
    margin-top: $space-small;
  }

  > li > button {
    align-items: center;
    cursor: pointer;
    display: flex;
    line-height: 1.4;

    &:hover {
      text-decoration: underline;
    }
  }

  > li > button > svg {
    color: var(--color-icon-default);
    margin-right: $space-small;
    width: $space;
  }

  > li > span {
    line-height: 1;
    margin-left: math.div($space, 2);

    svg {
      color: var(--color-text-default);
      height: $space;
    }
  }
}
