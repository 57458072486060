@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Pagination
/* ---------------------------------------------------------------------- */

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  margin-top: $space * 2;

  a {
    padding: math.div($space-small, 2) math.div($space-large, 2);
    position: relative;
    display: block;
    color: var(--color-text-link);
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline;
  }

  li:first-child a {
    padding-left: 0;
  }

  li.page-item.active {
    display: none;
  }
}

.pagination-active > a {
  border-radius: $border-radius;
  color: #fff;
  background-color: var(--color-button-primary-default);
  z-index: 3;
}

.pagination-disabled {
  a {
    color: var(--color-text-faint);
    cursor: not-allowed;
  }
  a:hover {
    text-decoration: none;
  }
}
