@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Cart
/* ---------------------------------------------------------------------- */

.cart {
  background: var(--color-surface-light);
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: $space;
  padding: $space;
}

.cart-footnote {
  text-align: right;
}

.cart-item {
  padding: $space-small;

  & + &::before {
    background: transparent;
    border-top: 1px dashed var(--color-border-default);
    content: '';
    display: block;
    height: 0;
    margin: math.div($space-small, 2) 0 $space;
  }
}

.cart-item-description {
  margin-top: math.div($space-small, 2);
}

.cart-item-details {
  display: flex;
  flex-direction: row;
  gap: 2 * $space;

  & > div:first-of-type {
    flex: 1;
  }
}

.cart-item-name {
  font-weight: $font-weight-bold;
}

.cart-item-notes {
  font-size: 12px;
  margin-top: $space;

  li:not(:first-of-type) {
    margin-top: $space;
  }
}

.cart-item-price {
  flex-shrink: 0;
}

.cart-total {
  border-top: 1px solid var(--color-border-default);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: $space-small;

  & > strong {
    text-transform: uppercase;
  }
}
