@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* AskWhy Tab
/* ---------------------------------------------------------------------- */

.ask-why-tab {
  display: flex;
  flex-direction: column;
  gap: $space;
}

.ask-why-tab-link {
  text-align: right;
}

.ask-why-tab-iframe {
  border: 1px solid var(--color-border-default);
  border-radius: $border-radius;
  overflow: hidden;
  width: 100%;
}
