@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* SuggestionsWrapper
/* ---------------------------------------------------------------------- */

.suggestions-wrapper-header {
  padding: $space-small;
  position: relative;

  > a {
    color: var(--color-text-faint);
    float: right;
    line-height: 1;

    &:hover {
      color: var(--color-text-link);
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  > h4 {
    margin: 0 (2 * $space) 0 0;

    &:not(:last-child) {
      margin-bottom: $space-small;
    }
  }

  > p {
    color: var(--color-text-subdued);
    font-size: 12px;
  }
}

.suggestions-wrapper-list {
  max-height: 175px;
  overflow-y: scroll;

  // b is the element used by react-mentions, this cannot be `strong`
  b {
    font-weight: bold;
  }
}
