@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Error
/* ---------------------------------------------------------------------- */

.error {
  margin: 0 auto;
  padding: $space-large * 3;
  text-align: center;
  width: math.div($max-width, 3);

  p {
    color: var(--color-text-subdued);
    margin-top: $space-large;
  }

  img {
    margin-bottom: $space-large;
    max-height: math.div($max-width, 5);
    max-width: math.div($max-width, 5);
  }

  button {
    margin-top: $space-large;
  }
}
