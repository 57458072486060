@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Report Name
/* ---------------------------------------------------------------------- */

.report-name {
  align-items: center;
  display: flex;

  > a {
    margin-right: math.div($space-small, 2);
  }

  > ul {
    align-items: center;
    display: flex;

    > li {
      margin-left: math.div($space-small, 2);

      svg {
        height: $space;
        width: $space;
      }
    }
  }
}
