@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Icon CTA
/* ---------------------------------------------------------------------- */

.icon-cta {
  align-items: center;
  color: inherit;
  cursor: pointer;
  display: flex;
  outline: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  > span {
    align-items: center;
    display: flex;
    &:nth-child(2) {
      margin-left: math.div($space, 2);
    }
  }
}
