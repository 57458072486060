@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Icon Button
/* ---------------------------------------------------------------------- */

.icon-button {
  align-items: center;
  background: transparent;
  border: 0;
  border-radius: $border-radius;
  color: var(--icon-button-color, var(--color-icon-default));
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  gap: math.div($space-small, 2);
  height: calc(var(--size-icon) + (2 * var(--icon-button-padding, 0)));
  justify-content: center;
  line-height: inherit;
  padding: var(--icon-button-padding, 0);
  transition: color $transition-duration;

  svg {
    height: var(--size-icon);
    width: var(--size-icon);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(:disabled) {
    &:active {
      color: var(--icon-button-color-pressed, var(--color-icon-pressed));
    }
    &:hover {
      color: var(--icon-button-color-hover, var(--color-icon-hover));
    }
  }

  &:focus-visible {
    @extend %outline-offset;
  }
}

.icon-button-danger {
  --icon-button-color-hover: var(--color-icon-danger);
  --icon-button-color-pressed: var(--color-icon-danger);
}

.icon-button-favorite {
  --icon-button-color-hover: #{$color-yellow-100};
  --icon-button-color-pressed: #{$color-yellow-100};

  &.is-favorite {
    --icon-button-color: #{$color-yellow-100};
  }
}
