@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Editable Title
/* ---------------------------------------------------------------------- */

.editable-title {
  display: inline-block;
  font-family: inherit;
  margin-bottom: $space-small;

  &.is-disabled h1 {
    padding: 0;
  }

  &.has-error input,
  &.has-error input:hover:not(:focus) {
    border-color: var(--color-text-error);
  }

  h1 {
    color: transparent;
    display: inline-block;
    height: $button-height;
    margin-bottom: 0;
    max-width: none;
    padding: math.div($space-small, 2);

    &:before {
      content: '-';
    }
  }

  input {
    background: none;
    border: 1px dashed var(--color-border-default);
    border-radius: $border-radius;
    color: var(--color-text-default);
    font-family: inherit;
    font-size: 22px;
    height: $button-height;
    left: 0;
    max-width: none;
    overflow: hidden;
    padding: math.div($space-small, 2);
    position: absolute;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    vertical-align: middle;

    &:focus,
    &:hover {
      border-color: var(--color-border-focus);
      outline: none;
    }

    &:disabled {
      border: none;
      padding: 0;
    }
  }
}

.editable-title-input-wrapper {
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.editable-title-error {
  align-items: center;
  color: var(--color-text-error);
  display: flex;
  margin-top: math.div($space-small, 2);

  &:before {
    background-color: var(--color-icon-danger);
    border-radius: 50%;
    content: '';
    height: $space-small;
    margin-right: $space-small;
    min-width: $space-small;
  }
}
