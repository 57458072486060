@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Sort Indicator
/* ---------------------------------------------------------------------- */

.sort-indicator {
  align-items: center;
  color: var(--color-text-default);
  cursor: pointer;
  display: inline-flex;
  flex-direction: inherit;
  justify-content: center;
  margin-left: math.div($space-small, 2);
}

.is-disabled {
  opacity: 0.5;
}
