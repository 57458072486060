@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* SearchOptions
/* ---------------------------------------------------------------------- */

.search-options-searchbar {
  margin-bottom: $space-large;

  > div > div,
  input {
    width: 100%;
  }
}

.search-options-results {
  display: flex;
  justify-content: space-between;
  min-height: $space * 15;
}

.search-options-loader {
  height: $space * 15;
  width: 500px;
}

.search-options-empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $space;
}

.search-options-list {
  > li {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
  }

  > li + li {
    margin-top: $space-small;
  }
}
