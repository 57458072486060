@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Automate
/* ---------------------------------------------------------------------- */

.modal-automate-details {
  display: flex;

  > div {
    margin-top: $space-large;
  }

  > div + div {
    margin-left: $space-small;
  }
}

.modal-automate-runs {
  margin-bottom: math.div($space-small, 4);
  margin-top: $space-large;
}
