@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Modal Validation 
/* ---------------------------------------------------------------------- */

.modal-validation {
  ul {
    margin-top: $space-large;
  }

  li + li {
    margin-top: $space-small;
  }
}
