@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Maintenance
/* ---------------------------------------------------------------------- */

.maintenance {
  align-items: center;
  background: url('assets/data-wave.svg');
  background-position: center 75vh;
  background-repeat: no-repeat;
  display: flex;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100vw;

  &:before {
    background: linear-gradient(to right, $color-green-100, $color-mint-100);
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 60%;
    z-index: -2;
  }

  &:after {
    background: linear-gradient(to bottom, #fff, transparent);
    content: '';
    height: 350px;
    left: 0;
    position: absolute;
    right: 0;
    top: 60%;
    z-index: -1;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: $space-large * 4;
    text-align: center;
  }

  button {
    color: var(--color-text-link);
    cursor: pointer;
    line-height: 1;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    color: $color-sapphire-500;
    height: auto;
    margin: 0 auto $space-large auto;
    width: 50%;
  }

  h1 {
    color: var(--color-neutral-700);
    font-size: 32px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

:global(.dark) .maintenance {
  &:before {
    background: linear-gradient(to right, $color-neutral-900, $color-sapphire-500);
  }
  &:after {
    background: linear-gradient(to bottom, $color-sapphire-900, transparent);
  }

  svg {
    color: #fff;
  }
}

.maintenance-status {
  position: absolute;
  left: $space-large;
  top: $space-large;
}

.maintenance-sign-out {
  position: absolute;
  right: $space-large;
  top: $space-large;
}
