@import '../../_variables.scss';
$card-status-border: 5px;

/* ---------------------------------------------------------------------- */
/*  Card
/* ---------------------------------------------------------------------- */

.card {
  background: var(--color-surface-default);
  border: 0;
  border-radius: $border-radius;
  box-shadow: $box-shadow-inset;
  list-style: none;
  padding: $space;
  position: relative;

  & + & {
    margin-top: $space;
  }

  &.is-elevation-1 {
    box-shadow: $box-shadow-shallow;
  }

  &.is-elevation-2 {
    box-shadow: $box-shadow;
  }

  &.is-padding-small {
    padding: $space-small;
  }
  &.is-padding-large {
    padding: $space-large;
  }
  &.is-padding-x-large {
    padding: 2 * $space;
  }

  &.is-status-color,
  &.is-status-error,
  &.is-status-info,
  &.is-status-success,
  &.is-status-warn {
    --status-color: #{$color-neutral-100};
    padding-left: $space + $card-status-border;

    &::after {
      background-color: var(--status-color);
      border-radius: $border-radius 0 0 $border-radius;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: $card-status-border;
    }
  }

  &.is-status-error {
    --status-color: #{$color-red-100};
  }
  &.is-status-success {
    --status-color: #{$color-sapphire-100};
  }
  &.is-status-warn {
    --status-color: #{$color-yellow-100};
  }
}
