@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Charts
/* ---------------------------------------------------------------------- */

.charts {
  display: flex;
  max-width: 100%;

  > div {
    flex: 0 0 calc((100% - #{$space}) / 2);
    max-width: calc((100% - #{$space}) / 2);

    &:only-child {
      flex-basis: 100%;
      max-width: 100%;
    }
  }

  > div + div {
    margin-left: $space;
  }
}

.charts-title {
  color: var(--color-text-brand);
  padding-top: $space-large;
}

.charts-filters {
  margin: $space-large 0;

  > div {
    display: flex;
    gap: $space;
    margin: $space 0;

    > div > div {
      min-width: 0;
    }
  }
}

.charts-filter {
  max-width: 600px;
}
