@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* Stepper
/* ---------------------------------------------------------------------- */

.stepper {
  border-top: 1px solid var(--color-border-subdued);
  display: flex;
  flex-direction: row;
  margin-bottom: $space;
  position: relative;

  > li {
    flex: 1 0;
  }
}
