@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Tree
/* ---------------------------------------------------------------------- */

.tree-row-card {
  & + & {
    margin-top: $space * 2;
  }
}

.tree-row {
  display: flex;
}
