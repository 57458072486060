@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Questionnaire
/* ---------------------------------------------------------------------- */

.questionnaire {
  > h3 {
    margin-bottom: $space;
  }
}

.questionnaire-question {
  align-items: center;
  box-shadow: $elevation-100 !important; // TODO: this should be applied to Card's elevation scss
  margin-top: 0 !important;

  > strong:first-child {
    margin-top: -2px;
    padding: $space-small;
  }
}
