@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Confirmation
/* ---------------------------------------------------------------------- */

.confirmation {
  display: flex;
  flex-direction: column;
  gap: $space;
  margin: (5 * $space-large) auto 0;
  max-width: 440px;
  text-align: center;

  h1,
  h2 {
    margin-bottom: 0;
  }

  img {
    margin: 0 auto;
    width: 225px;
  }

  p {
    color: var(--color-text-subdued);
    margin-bottom: $space;
  }
}
