@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Toolbar
/* ---------------------------------------------------------------------- */

.download {
  &.is-disabled {
    pointer-events: none;
  }
}
