@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* ModalOnboarding
/* ---------------------------------------------------------------------- */

.modal-onboarding {
  border-bottom: 1px solid var(--color-border-subdued);
  padding-bottom: $space * 2;
}

.modal-onboarding-step-indicator {
  bottom: -55px;
  display: flex;
  left: 25px;
  position: absolute;

  > span {
    border: 1px solid var(--color-button-primary-default);
    border-radius: 50%;
    height: $space-small;
    margin: 0 5px;
    width: $space-small;
    &.active {
      background-color: var(--color-button-primary-default);
    }
  }
}
