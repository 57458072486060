@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Checkout Success
/* ---------------------------------------------------------------------- */

.checkout-success {
  padding-top: 2 * $space;

  & > p {
    margin-bottom: 2 * $space;
  }
}

.checkout-success-announcement {
  margin-bottom: 2 * $space !important;
}
