@use 'sass:math';
@import '../../_variables.scss';

/* ---------------------------------------------------------------------- */
/*  Tag
/* ---------------------------------------------------------------------- */

.tag {
  align-items: center;
  display: inline-flex;
  font-size: 14px;
  gap: math.div($space-small, 2);
  line-height: var(--size-tag);
  white-space: nowrap;

  > svg {
    color: var(--color-text-faint);
    height: var(--size-tag);
    vertical-align: middle;
    width: var(--size-tag);
  }

  & + & {
    margin-left: $space-small;
  }
}
