@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Details
/* ---------------------------------------------------------------------- */

.details {
  padding-bottom: $space-large * 3;

  > h3 {
    margin-bottom: $space;
  }
}

.details-card {
  margin-top: 0 !important;
}

.details-group-color {
  background: var(--color-icon-default);
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: math.div($space-small, 2);
  margin-top: -3px;
  vertical-align: middle;
  width: 12px;
}

.details-group-divider {
  background: var(--color-border-subdued);
  width: 1px;
}

.details-group-response-counts {
  display: flex;
  justify-content: space-between;
  margin-bottom: math.div($space-small, 2);
}

.details-list {
  margin-bottom: $space * 2;
}
