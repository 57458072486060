@use 'sass:math';
@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Item
/* ---------------------------------------------------------------------- */

.item-label {
  @extend %label;
  > svg {
    color: var(--color-text-error);
    margin-left: math.div($space-small, 2);
  }
}

.item-selections {
  align-items: center;
  background-color: var(--color-surface-light);
  border: 1px solid var(--color-border-default);
  border-radius: $border-radius;
  display: flex;
  min-height: $space-large * 2;
  transition: none;

  button {
    background-color: var(--color-surface-medium);
    margin-left: $space;
  }
}

.item-search {
  padding: $space-small !important;
}

.item-search-overlay-popover {
  margin-top: math.div($space, 2);
  min-width: 603px;
}
