@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Prompts
/* ---------------------------------------------------------------------- */

.prompts {
  padding-bottom: $space-small;

  > div:last-of-type {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }
}
