@import '../../../../_variables.scss';

/* ---------------------------------------------------------------------- */
/* StepIndicator
/* ---------------------------------------------------------------------- */

.step-indicator {
  background: var(--color-text-brand);
  display: block;
  height: 2px;
  pointer-events: none;
  position: absolute;
  transition: left 500ms ease-out, width 500ms ease-out;
  top: -1px;
}
