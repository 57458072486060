@import '@utilities/_variables.scss';

/* ---------------------------------------------------------------------- */
/* Question Logic Condition
/* ---------------------------------------------------------------------- */

.question-logic-condition {
  display: flex;
  justify-content: space-between;
  min-height: $space-small * 3;
}

.question-logic-text {
  align-items: center;
  display: flex;
  gap: $space-small;
}
